import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ReactComponent as Logo } from "../logo.svg";

import Alert from "components/shared-components/alert/alert";
import InputMask from "react-input-mask";

import {
  toggleSignUpPopup,
  switchToSignIn,
  switchToQuestions,
} from "redux/popup/popup.actions";

import {
  signUp,
  signIn,
  confirmPasswordFailure,
  clearError,
  insertUser,
  insertFilter,
  insertUserFilter,
} from "redux/auth/auth.actions";

import { useWindowDimensions } from "hooks/useWindowDimensions";

import { clearForm } from "utilis/clearForm";

import "../popup.scss";

const SignUpPopUp = ({
  isPopupVisible,
  switchToQuestions,
  switchToSignIn,
  signUp,
  signIn,
  insertUser,
  insertFilter,
  insertUserFilter,
  clearError,
  confirmPasswordFailure,
  loading,
  questions,
  errorMessage,
  loggingIn,
  insertingUser,
  mixpanel,
}) => {
  const { width } = useWindowDimensions();

  const [invisiblePassword, setInvisiblePassword] = useState(true);
  const [userCredentials, setCredentials] = useState({
    name: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
  });

  const { name, lastname, email, password, confirmPassword, phone } =
    userCredentials;

  useEffect(() => {
    mixpanel.track("Sign up popup");
    return () => {
      clearForm(userCredentials, setCredentials);
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const { value, name } = event.target;
    setCredentials({ ...userCredentials, [name]: value });
    if (errorMessage) clearError();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    mixpanel.track("Pressed Register button on Sign up popup");

    if (!email || !phone || !name) {
      return confirmPasswordFailure("Name, email and phone are required");
    }

    if (!password && !confirmPassword) {
      return confirmPasswordFailure("Type your password");
    }

    if (password !== confirmPassword)
      return confirmPasswordFailure(
        "Your password doesn't match, try it again!"
      );

    const signUpResponse = await signUp(name, lastname, email, password, phone);

    if (signUpResponse) {
      // await signIn(email, password);
      await insertUser(name, lastname, phone, email);
      let filterResponse = await insertFilter();
      await insertUserFilter(filterResponse.FilterId, email);
      // switchToQuestions();
    }
  };

  return (
    <div className={`popup ${isPopupVisible ? "is-active" : ""}`}>
      <div className={`popup-form ${width <= 767 ? "register-mobile" : ""}`}>
        <div className="logo">
          <Logo />
        </div>
        <div className="form-holder">
          <form onSubmit={handleSubmit}>
            <div className="form-input half">
              <input
                type="text"
                placeholder="Name"
                name="name"
                onChange={handleChange}
                value={name}
                style={{ textTransform: "capitalize" }}
              />
              <input
                type="text"
                placeholder="Last Name"
                name="lastname"
                onChange={handleChange}
                value={lastname}
                style={{ textTransform: "capitalize" }}
              />
            </div>
            <div className="form-input half">
              <input
                type="text"
                placeholder="Email"
                name="email"
                onChange={handleChange}
                value={email}
              />
              {/* <input
                type="text"
                placeholder="Phone No."
                name="phone"
                onChange={handleChange}
                value={phone}
              /> */}
              <InputMask
                mask="(999) 999-9999"
                placeholder="Phone No."
                name="phone"
                onChange={handleChange}
                value={phone}
              />
            </div>
            <div className="form-input half">
              <div className="input-ctn">
                <input
                  type={invisiblePassword ? "password" : "text"}
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  value={password}
                />
                
                <span
                  className={`show-password ${
                    !invisiblePassword ? "is-input-plain" : ""
                  }`}
                  onClick={() => setInvisiblePassword(!invisiblePassword)}
                >
                  <svg width="16" height="10.909" viewBox="0 0 16 10.909">
                    <path
                      d="M8,10.909a8.533,8.533,0,0,1-4.892-1.53A8.608,8.608,0,0,1,0,5.455a8.6,8.6,0,0,1,16,0,8.607,8.607,0,0,1-3.108,3.924A8.533,8.533,0,0,1,8,10.909ZM8,1.818a3.636,3.636,0,1,0,3.636,3.637A3.641,3.641,0,0,0,8,1.818ZM8,7.637a2.182,2.182,0,1,1,2.182-2.182A2.184,2.184,0,0,1,8,7.637Z"
                      transform="translate(0 0)"
                      fill="#d0c9d6"
                    />
                  </svg>
                </span>
              </div>
              <div className="input-ctn">
                <input
                  type="password"
                  placeholder="Re-Password"
                  name="confirmPassword"
                  onChange={handleChange}
                  value={confirmPassword}
                />
              </div>
            </div>

            <div className="form-input">
              {loggingIn ? (
                <button
                  className="button button--default"
                  // disabled={loggingIn ? true : false}
                >
                  LOGGING IN...
                </button>
              ) : (
                <button
                  className="button button--default"
                  disabled={insertingUser || loading ? true : false}
                >
                  {/* {insertingUser && !loading
                    ? "LOADING..."
                    : loading
                    ? "REGISTERING..."
                    : "REGISTER"} */}

                  {loading ? "REGISTERING..." : "REGISTER"}
                </button>
              )}
            </div>
          </form>
        </div>

        <p className="confirm-acc-alert">
          * Your password must contain minimum 8 characters, at least one
          uppercase and lowercase, at least one number and one special
          character.
        </p>

        <span className="caption-connect bottom-gap is-centered">
          Already have an account?
          <button className="button-switch" onClick={switchToSignIn}>
            Login here
          </button>
        </span>
      </div>

      {errorMessage && <Alert message={errorMessage} />}
    </div>
  );
};

const mapStateToProps = ({
  popups: { registerPopupShown },
  auth: { loading, errorMessage, loggingIn, insertingFilter },
  questions: { questions },
}) => ({
  isPopupVisible: registerPopupShown,
  loading,
  questions,
  errorMessage,
  loggingIn,
  insertingUser: insertingFilter,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSignUpPopup: () => dispatch(toggleSignUpPopup()),
  switchToSignIn: () => dispatch(switchToSignIn()),
  signUp: (name, lastname, email, password, phone) =>
    dispatch(signUp({ name, lastname, email, password, phone })),
  confirmPasswordFailure: (message) =>
    dispatch(confirmPasswordFailure(message)),
  clearError: () => dispatch(clearError()),
  insertUser: (firstname, lastname, phone, email) =>
    dispatch(insertUser({ firstname, lastname, phone, email })),
  insertFilter: () => dispatch(insertFilter()),
  insertUserFilter: (filter, email) =>
    dispatch(insertUserFilter({ filter, email })),
  signIn: (username, password) => dispatch(signIn({ username, password })),
  switchToQuestions: () => dispatch(switchToQuestions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPopUp);
