import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as Successful } from "./successful.svg";
import { ReactComponent as Error } from "./error.svg";

import {
  toggleConfirmEmailPopup,
  switchToSignIn,
} from "redux/popup/popup.actions";

const ConfirmEmailPopup = ({
  toggleConfirmEmailPopup,
  switchToSignIn,
  emailVerified,
}) => {
  let history = useHistory();
  return (
    <div className="popup">
      <div className="popup-form login-form email-confirm">
        <div className="logo no-padding">
          {emailVerified ? <Successful /> : <Error />}
        </div>
        <div className={`form-holder ${emailVerified ? "blue" : "red"}`}>
          <h2 className="confirm-message">
            {emailVerified ? "Successful" : "Something went wrong!"}
          </h2>
          <p className="confirm-text">
            Your email is {emailVerified ? "" : "not"} confirmed
          </p>
          <div className="form-input">
            <button
              className={`button ${
                emailVerified ? "button--light" : "button--red"
              }`}
              onClick={() => {
                if (emailVerified) switchToSignIn();
                else {
                  toggleConfirmEmailPopup();
                  history.push("/");
                }
              }}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { emailVerified } }) => ({
  emailVerified,
});

const mapDispatchToProps = (dispatch) => ({
  switchToSignIn: () => dispatch(switchToSignIn()),
  toggleConfirmEmailPopup: () => dispatch(toggleConfirmEmailPopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailPopup);
