import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../logo.svg";
import { connect } from "react-redux";

import Alert from "components/shared-components/alert/alert";

import {
  switchToSignUp,
  toggleSignupVerificationCodePopup,
  toggleScheduleCallPopup,
} from "redux/popup/popup.actions";

import {
  confirmSignUpVerificationCode,
  clearError,
} from "redux/auth/auth.actions";

const SignupVerificationCode = ({
  switchToSignUp,
  toggleSignupVerificationCodePopup,
  toggleScheduleCallPopup,
  confirmSignUpVerificationCode,
  errorMessage,
  clearError,
  loading,
}) => {
  const formSubmit = (e) => {
    e.preventDefault();
    if (input) {
      confirmSignUpVerificationCode(input);
    }
  };

  const [input, setInput] = useState("");

  const changeInput = (e) => {
    setInput(e.target.value);
    if (errorMessage) clearError();
  };

  useEffect(() => {
    clearError();
    return () => {
      clearError();
    };
  }, []);

  return (
    <div className="popup page-signup-verification-code">
      <div className="popup-form login-form">
        <div className="logo">
          <Logo />
        </div>
        <div className="form-holder">
          <form onSubmit={formSubmit}>
            <div className="form-input">
              <input
                type="text"
                placeholder="Verification Code"
                name="verificationCode"
                value={input}
                onChange={changeInput}
              />
            </div>
            <div className="form-input">
              <button className="button button--light">
                {loading ? "Loading..." : "Continue"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <p className="popup_message">
        If you don’t have a code, please {""}
        <span
          onClick={() => {
            toggleSignupVerificationCodePopup();
            toggleScheduleCallPopup();
          }}
        >
          schedule call.
        </span>
      </p>
      {errorMessage && <Alert message={errorMessage} />}
    </div>
  );
};

const mapStateToProps = ({
  popups: { enterCodePopupShown, email },
  auth: { loading, errorMessage },
}) => ({
  isPopupVisible: enterCodePopupShown,
  email,
  loading,
  errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  switchToSignUp: () => dispatch(switchToSignUp()),
  toggleSignupVerificationCodePopup: () =>
    dispatch(toggleSignupVerificationCodePopup()),
  toggleScheduleCallPopup: () => dispatch(toggleScheduleCallPopup()),
  confirmSignUpVerificationCode: (code) =>
    dispatch(confirmSignUpVerificationCode(code)),
  clearError: () => dispatch(clearError()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupVerificationCode);
