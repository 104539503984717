export const FETCH_SINGLE_PROPERTY_START = "FETCH_SINGLE_PROPERTY_START";
export const FETCH_SINGLE_PROPERTY_SUCCESS = "FETCH_SINGLE_PROPERTY_SUCCESS";
export const FETCH_SINGLE_PROPERTY_FAILURE = "FETCH_SINGLE_PROPERTY_FAILURE";

export const TOGGLE_SINGLE_DEAL_STATUS = "TOGGLE_SINGLE_DEAL_STATUS";

export const CLEAR_PROPERTY = "CLEAR_PROPERTY";

export const FETCH_FAVORITE_STATUS_SUCCESS = "FETCH_FAVORITE_STATUS_SUCCESS";
export const FETCH_FAVORITE_STATUS_FAILURE = "FETCH_FAVORITE_STATUS_FAILURE";

export const SET_ACQUISITION_TEAM = "SET_ACQUISITION_TEAM";
