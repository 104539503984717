import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { ReactComponent as Logo } from "../logo.svg";
import Input from "components/shared-components/input-field/input-field";

import Alert from "components/shared-components/alert/alert";

import {
  signIn,
  clearError,
  isRegistred,
} from "redux/auth/auth.actions";
import {
  toggleSignInPopup,
  toggleForgotPasswordPopup,
  switchToSignUp,
  toggleMobileMenu,
} from "redux/popup/popup.actions";

import { clearForm } from "utilis/clearForm";

import "../popup.scss";

const LoginPopUp = withRouter(
  ({
    signIn,
    toggleSignInPopup,
    toggleMobileMenu,
    switchToSignUp,
    switchToForgotPassword,
    loading,
    redirectPath,
    history,
    errorMessage,
    mobileMenuActive,
    clearError,
    mixpanel,
    isRegistred,
  }) => {
    const [invisiblePassword, setInvisiblePassword] = useState(true);

    const [userCredentials, setCredentials] = useState({
      username: "",
      password: "",
    });

    const { username, password } = userCredentials;

    const handleChange = (event) => {
      const { value, name } = event.target;
      setCredentials({ ...userCredentials, [name]: value });
      if (errorMessage) clearError();
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      mixpanel.track("Pressed Login button on Login popup");
      let signinResponse = undefined;


      const isRegistredResponse = await isRegistred(username, password);
      if (isRegistredResponse)
        signinResponse = await signIn(username, password);

      if (signinResponse && !loading) {
        toggleSignInPopup("/search/page/1?state=Texas");
        if (mobileMenuActive) toggleMobileMenu();

        history.push("/search/page/1?state=Texas");
      } else {
    }
    };

    useEffect(() => {
      mixpanel.track("Login popup");
      clearError();
      return () => {
        clearForm(userCredentials, setCredentials);
        clearError();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
      <div className="popup" data-testid="login-popup">
        <div className="popup-form login-form">
          <div className="logo" data-testid="logo">
            <Logo />
          </div>
          <div className="form-holder">
            <form onSubmit={handleSubmit} data-testid="login-form">
              <div className="form-input">
                <Input
                  type="text"
                  label="Email"
                  name="username"
                  id="username"
                  onChange={handleChange}
                  value={username}
                />
              </div>
              <div className="form-input">
                <Input
                  type={invisiblePassword ? "password" : "text"}
                  label="Password"
                  name="password"
                  id="password"
                  autoComplete="off"
                  onChange={handleChange}
                  value={password}
                />
                <span
                  className={`show-password ${
                    !invisiblePassword ? "is-input-plain" : ""
                  }`}
                  onClick={() => setInvisiblePassword(!invisiblePassword)}
                >
                  <svg width="16" height="10.909" viewBox="0 0 16 10.909">
                    <path
                      d="M8,10.909a8.533,8.533,0,0,1-4.892-1.53A8.608,8.608,0,0,1,0,5.455a8.6,8.6,0,0,1,16,0,8.607,8.607,0,0,1-3.108,3.924A8.533,8.533,0,0,1,8,10.909ZM8,1.818a3.636,3.636,0,1,0,3.636,3.637A3.641,3.641,0,0,0,8,1.818ZM8,7.637a2.182,2.182,0,1,1,2.182-2.182A2.184,2.184,0,0,1,8,7.637Z"
                      transform="translate(0 0)"
                      fill="#d0c9d6"
                    />
                  </svg>
                </span>
              </div>
              <div className="form-input">
                {/* <div className="checkbox">
                  <input
                    type="checkbox"
                    name="remember-password"
                    id="remember-password"
                  />
                  <label htmlFor="remember-password">Remember me</label>
                </div> */}
                <div className={`forget-password`}>
                  <span
                    className="button-switch"
                    onClick={switchToForgotPassword}
                  >
                    Forgot Password?
                  </span>
                </div>
              </div>
              <div className="form-input">
                <button
                  data-testid="login-button"
                  className="button button--default"
                  disabled={loading ? true : false}
                >
                  {loading ? "Logging in..." : "Login"}
                </button>
              </div>
            </form>
          </div>
          {/* <span className="caption-connect bottom-gap is-centered">
            Not registered yet? Please
            <button className="button-switch" onClick={switchToSignUp}>
              Sign Up
            </button>
          </span> */}
        </div>

        {errorMessage && <Alert message={errorMessage} />}
      </div>
    );
  }
);

const mapStateToProps = ({
  popups: { redirectPath, mobileMenuActive },
  auth: { loggingIn, errorMessage },
}) => ({
  loading: loggingIn,
  redirectPath,
  mobileMenuActive,
  errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  signIn: (username, password) => dispatch(signIn({ username, password })),
  toggleSignInPopup: (redirectPath) =>
    dispatch(toggleSignInPopup(redirectPath)),
  switchToSignUp: () => dispatch(switchToSignUp()),
  switchToForgotPassword: () => dispatch(toggleForgotPasswordPopup()),
  toggleMobileMenu: () => dispatch(toggleMobileMenu()),
  clearError: () => dispatch(clearError()),
  isRegistred: (email, password) => dispatch(isRegistred(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPopUp);
