import React from "react";

import "./loading-indicator.scss";

const LoadingIndicator = ({ top, hide, smallWidth }) => (
  <div
    className={`loading-items-holder ${hide && "hide"} ${
      smallWidth && "small-width"
    }`}
  >
    <ul className="loading-items" style={{ top: top ? top : "50%" }}>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
);

export default LoadingIndicator;
