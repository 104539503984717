import { formatNumber } from "utilis/formatNumber";

export const formatProperty = (property) => {
  return {
    Address: property.Address.split(",")[0],
    City: property.City,
    Beds: property.Num_Beds,
    Baths: property.Num_Baths,
    Year_Built: property.Year_Built,
    Square_Feet: formatNumber(property.Square_Footage),
    PPSF: "$" + Math.round(property.PPSF),
    Lot_Size: formatNumber(property.Lot_Size),
    Days_On_Market: property.Days_on_Market,
  };
};

export const formatMapItem = (mapItem) => {
  if (mapItem)
    return {
      ...mapItem,
      lat: mapItem.latitude ? Number(mapItem.latitude) : mapItem.Lat,
      lon: mapItem.longitude ? Number(mapItem.longitude) : mapItem.Lon,
      id: mapItem.id ? mapItem.id : mapItem.Unique_ID,
      address: mapItem.address ? mapItem.address : mapItem.Address,
      list_price: mapItem.list_price ? mapItem.list_price : mapItem.List_Price,
      images: mapItem.images ? mapItem.images : mapItem.Images,
      beds: mapItem.beds ? mapItem.beds : mapItem.Num_Beds,
      baths: mapItem.baths ? mapItem.baths : mapItem.Num_Baths,
      square_footage: mapItem.square_footage
        ? mapItem.square_footage
        : mapItem.Square_Footage,
    };
};

export const formatAcquisitionTeam = (team) => {
  return team.map((member) => {
    return member.Attributes;
  });
};

export const formatComp = (comps) => {
  return comps.map((comp) => {
    const formatedDate = formatUnixDate(comp.Date_Sold);
    return {
      ...comp,
      Date_Sold: formatedDate,
      PPSF: "$" + Math.round(comp.PPSF),
      Sale_Price: "$" + formatNumber(comp.Sale_Price),
      Lot_Size: formatNumber(comp.Lot_Size),
      Square_Feet: formatNumber(comp.Square_Feet),
      Distance: comp.Distance ? comp.Distance.toFixed(2) : "",
    };
  });
};

export const averageRow = (comps) => {
  let bedsaverage = average(comps, "Beds");
  let bathssaverage = average(comps, "Baths");
  let builtaverage = average(comps, "Year_Built");
  let sqftavg = formatNumber(average(comps, "Square_Feet"));
  let sqftPriceavg = "$" + average(comps, "PPSF");
  let acresavg = formatNumber(average(comps, "Lot_Size"));
  let priceavg = "$" + formatNumber(average(comps, "Sale_Price"));
  let daysonmarketavg = average(comps, "Days_On_Market");
  let distanceAvg = average(comps, "Distance");

  return {
    Address: "Averages:",
    City: "",
    Beds: bedsaverage,
    Baths: bathssaverage,
    Year_Built: builtaverage,
    Square_Feet: sqftavg,
    PPSF: sqftPriceavg,
    Lot_Size: acresavg,
    Distance: distanceAvg,
    Sale_Price: priceavg,
    Date_Sold: "",
    Days_On_Market: daysonmarketavg,
  };
};

export const calculateSqftMedian = (comps) => {
  let ppsqft = comps.map(({ PPSF }) => PPSF);
  return formatNumber(median(ppsqft));
};

export const medianRow = (comps) => {
  let beds = comps.map(({ Beds }) => Beds);
  let Beds = median(beds);

  let baths = comps.map(({ Baths }) => Baths);
  let Baths = median(baths);

  let yearBuilt = comps.map(({ Year_Built }) => Year_Built);
  let Year_Built = median(yearBuilt);

  let sqft = comps.map(({ Square_Feet }) => Square_Feet);
  let Square_Feet = formatNumber(median(sqft));

  let ppsqft = comps.map(({ PPSF }) => PPSF);
  let PPSF = "$" + formatNumber(median(ppsqft));

  let acres = comps.map(({ Lot_Size }) => Lot_Size);
  let Lot_Size = formatNumber(median(acres));

  let price = comps.map(({ Sale_Price }) => Sale_Price);
  let Sale_Price = "$" + formatNumber(median(price));

  let dom = comps.map(({ Days_On_Market }) => Days_On_Market);
  let Days_On_Market = median(dom);

  let distance = comps.map(({ Distance }) => Distance);
  let Distance = median(distance);

  return {
    Address: "Medians:",
    City: "",
    Beds,
    Baths,
    Year_Built,
    Square_Feet,
    PPSF,
    Lot_Size,
    Distance,
    Sale_Price,
    Days_On_Market,
  };
};

function average(arr, field) {
  if (arr.length === 0) return 0;
  let res = arr.reduce((sum, item) => sum + item[field], 0) / arr.length;
  return Math.round(res) ? Math.round(res) : res;
}

function median(values) {
  if (values.length === 0) return 0;

  values.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return Math.round((values[half - 1] + values[half]) / 2.0)
    ? Math.round((values[half - 1] + values[half]) / 2.0)
    : (values[half - 1] + values[half]) / 2.0;
}

function formatUnixDate(incommingDate) {
  let date = new Date(incommingDate);

  let year = date.getFullYear().toString().substr(-2);
  let month = date.getMonth();
  let day = date.getDate();

  return month + "/" + day + "/" + year;
}
