import {
  FETCH_SINGLE_PROPERTY_START,
  FETCH_SINGLE_PROPERTY_SUCCESS,
  FETCH_SINGLE_PROPERTY_FAILURE,
  TOGGLE_SINGLE_DEAL_STATUS,
  CLEAR_PROPERTY,
  FETCH_FAVORITE_STATUS_SUCCESS,
  FETCH_FAVORITE_STATUS_FAILURE,
  SET_ACQUISITION_TEAM,
} from "./listing-detail.types";

import axios from "axios";
import { Auth } from "aws-amplify";
import { instance } from "api/rubik";

export const fetchSinglePropertyStart = () => ({
  type: FETCH_SINGLE_PROPERTY_START,
});

export const fetchSinglePropertySuccess = (property) => ({
  type: FETCH_SINGLE_PROPERTY_SUCCESS,
  payload: property,
});

export const fetchSinglePropertyFailure = (error) => ({
  type: FETCH_SINGLE_PROPERTY_FAILURE,
  payload: error,
});

export const toggleSingleDealStatus = () => ({
  type: TOGGLE_SINGLE_DEAL_STATUS,
});

export const clearProperty = () => ({
  type: CLEAR_PROPERTY,
});

export const fetchFavoriteStatusSuccess = (status) => ({
  type: FETCH_FAVORITE_STATUS_SUCCESS,
  payload: status,
});

export const fetchFavoriteStatusFailure = (error) => ({
  type: FETCH_FAVORITE_STATUS_FAILURE,
  payload: error,
});

export const setAcquisitionTeam = (team) => ({
  type: SET_ACQUISITION_TEAM,
  payload: team,
});

export const startFetchingSinglePropertyAsync = (id) => {
  return async (dispatch) => {
    dispatch(fetchSinglePropertyStart());
    dispatch(getAcquisitionTeam());

    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    const user_id = session.idToken.payload["cognito:username"];

    instance
      .get(
        `https://api.rubikhomes.com/crm-service/details?user_id=${user_id}&listing_id=${id}`,
        {
          headers: {
            "X-Rubik-Authorization": `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(fetchSinglePropertySuccess(res.data));
        if (res.data) {
          dispatch(fetchFavoriteStatusSuccess(res.data.favorite));
        }
      })
      .catch((err) => {
        console.log(err.response, err.message);
        dispatch(fetchSinglePropertyFailure(err.message));
      });
  };
};

export const getAcquisitionTeam = () => {
  return async (dispatch) => {
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    try {
      const response = await axios({
        method: "GET",
        url: `https://api.rubikhomes.com/user-service/acquisitions`,
        headers: {
          "X-Rubik-Authorization": `Bearer ${token}`,
        },
      });
      // console.log(response);
      dispatch(setAcquisitionTeam(response.data));
      // dispatch(setSelectedEditProperty(response.data[0]));
    } catch (err) {
      console.log(err.response);
    }
  };
};

export const checkFavoriteStatus = ({ id: propertyId }) => {
  return async (dispatch) => {
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;

    instance
      .get(
        `https://ng7vdo5u1c.execute-api.us-east-1.amazonaws.com/dev/search/v1/${propertyId}/is-favorite`,
        {
          headers: {
            "X-Rubik-Authorization": `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        dispatch(fetchFavoriteStatusSuccess(res.data.status));
      })
      .catch((err) => {
        dispatch(fetchFavoriteStatusFailure(err.message));
      });
  };
};
