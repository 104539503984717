export const SHOW_ITEMS_TO_MAP = "SHOW_ITEMS_TO_MAP";
export const SHOW_SAVED_ITEMS_TO_MAP = "SHOW_SAVED_ITEMS_TO_MAP";
export const SET_CURRENT_MAP_ITEM = "SET_CURRENT_MAP_ITEM";
export const SET_SELECTED_MAP_ITEMS = "SET_SELECTED_MAP_ITEMS";

export const SET_SCROLL_HEIGHT_RES = "SET_SCROLL_HEIGHT_RES";

export const CLEAR_MAP_ITEMS = "CLEAR_MAP_ITEMS";

export const SET_DRAGGING_ON = "SET_DRAGGING_ON";
export const SET_DRAGGING_OFF = "SET_DRAGGING_OFF";

export const ZOOM_CHANGED = "ZOOM_CHANGED";
export const SET_ZOOMABLE = "SET_ZOOMABLE";

export const SET_STATE_COORDINATES = "SET_STATE_COORDINATES";