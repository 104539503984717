import {
  SHOW_ITEMS_TO_MAP,
  SET_CURRENT_MAP_ITEM,
  SET_SCROLL_HEIGHT_RES,
  CLEAR_MAP_ITEMS,
  SHOW_SAVED_ITEMS_TO_MAP,
  SET_DRAGGING_OFF,
  SET_DRAGGING_ON,
  ZOOM_CHANGED,
  SET_ZOOMABLE,
  SET_STATE_COORDINATES,
} from "./map.types";

import { formatMapProperties } from "./map.utilis";

const initialState = {
  mapItems: [],
  currentItem: "",
  scrollPosition: 0,
  isDragging: false,
  draggingCoordinates: null,
  zoomCoordinates: null,
  zoomable: false,
  stateCoordinates: {},
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ITEMS_TO_MAP:
      return {
        ...state,
        mapItems: formatMapProperties(action.payload),
      };
    case SHOW_SAVED_ITEMS_TO_MAP:
      return {
        ...state,
        viewport: "",
        mapItems: formatMapProperties(action.payload),
      };
    case SET_CURRENT_MAP_ITEM:
      return {
        ...state,
        currentItem: action.payload,
      };
    case SET_SCROLL_HEIGHT_RES:
      return {
        ...state,
        scrollPosition: action.payload,
      };
    case CLEAR_MAP_ITEMS:
      return {
        ...state,
        mapItems: [],
      };
    case SET_DRAGGING_ON:
      return {
        ...state,
        isDragging: true,
      };
    case SET_DRAGGING_OFF:
      return {
        ...state,
        isDragging: false,
        draggingCoordinates: action.payload,
      };
    case ZOOM_CHANGED:
      return {
        ...state,
        zoomCoordinates: action.payload,
      };
    case SET_ZOOMABLE:
      return {
        ...state,
        zoomable: action.payload,
      };
    case SET_STATE_COORDINATES: {
      return {
        ...state,
        stateCoordinates: action.payload,
      };
    }
    default:
      return state;
  }
};

export default mapReducer;
