import React from "react";

import { ReactComponent as CheckEmail } from "./checkEmail.svg";

const CheckEmailPopup = () => {
  return (
    <div className="popup">
      <div className="popup-form login-form">
        <div className="form-holder align-center">
          <CheckEmail />
          <p className="confirm-text margin-top">
            Please check your email for the validation code to confirm your
            account.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CheckEmailPopup;
