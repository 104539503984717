import {
  FETCH_SAVED_DEALS_START,
  FETCH_SAVED_DEALS_SUCCESS,
  FETCH_SAVED_DEALS_FAILURE,
  FAVORITE_DEAL_SUCCESS,
  FAVORITE_DEAL_FAILURE,
  UNFAVORITE_DEAL_SUCCESS,
  UNFAVORITE_DEAL_FAILURE,
  UNFAVORITE_DEAL_SUCCESS_FROM_LIST,
} from "./saved-deals.types";

import { formatSavedDeals } from "./saved-deals.utilis";

const initialState = {
  savedDeals: [],
  isFetching: false,
  error: null,
  favoriteDealError: null,
  unFavoriteDealError: null,
  savedNotification: null,
};

const savedDealsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SAVED_DEALS_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SAVED_DEALS_SUCCESS:
      return {
        ...state,
        savedDeals: formatSavedDeals(action.payload),
        isFetching: false,
      };
    case FETCH_SAVED_DEALS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FAVORITE_DEAL_SUCCESS:
      return {
        ...state,
        savedNotification: action.payload,
      };
    case FAVORITE_DEAL_FAILURE:
      return {
        ...state,
        favoriteDealError: action.payload,
      };
    case UNFAVORITE_DEAL_SUCCESS:
      return {
        ...state,
        savedNotification: false,
        savedDeals: state.savedDeals.filter(
          (deal) => deal.id !== action.payload
        ),
      };
    case UNFAVORITE_DEAL_FAILURE:
      return {
        ...state,
        unFavoriteDealError: action.payload,
      };
    case UNFAVORITE_DEAL_SUCCESS_FROM_LIST:
      return {
        ...state,
        savedNotification: null,
      };

    default:
      return state;
  }
};

export default savedDealsReducer;
