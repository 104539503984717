import React from "react";

import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as TickIcon } from "./tick.svg";

import "./alert.scss";

const Alert = ({ message, success }) => (
  <div className={`alert ${success ? "green" : ""}`}>
    <div>{success ? <TickIcon /> : <CloseIcon />}</div>
    <div data-testid="alert-message">{message}</div>
  </div>
);

export default Alert;
