import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { withRouter, useLocation } from "react-router-dom";

import { ReactComponent as CloseIcon } from "./close.svg";

import "./modal.scss";

const imageSlider = document.getElementById("image-slider");

const Modal = ({ onClick, children, style, hideButton }) => {
  const location = useLocation();
  const onEscPressed = useCallback(
    ({ key }) => {
      if (key === "Escape") onClick();
    },
    [onClick]
  );

  useEffect(() => {
    window.addEventListener("keydown", onEscPressed);
    return () => {
      window.removeEventListener("keydown", onEscPressed);
    };
  });

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      className="modal-container"
      style={{ ...style }}
      // onClick={(e) => {
      //   if (!hideButton && e.target.className === "modal-container") {
      //     onClick();
      //   }
      // }}
    >
      {children}
      {/* {!hideButton && (
        <div
          className="close-button"
          onClick={(event) => {
            event.stopPropagation();
            onClick();
          }}
        >
          <CloseIcon />
        </div>
      )} */}
      {!hideButton && (
        <div className="close-button-ctn">
          <div
            onClick={(event) => {
              event.stopPropagation();
              onClick();
            }}
          >
            Close
          </div>
        </div>
      )}
    </div>,
    imageSlider
  );
};

export default withRouter(Modal);
