export const INSERT_USER_NEWSLETTER_START = "INSERT_USER_NEWSLETTER_START";
export const INSERT_USER_NEWSLETTER_SUCCESS = "INSERT_USER_NEWSLETTER_SUCCESS";
export const INSERT_USER_NEWSLETTER_FAILURE = "INSERT_USER_NEWSLETTER_FAILURE";

export const INSERT_FILTER_NEWSLETTER_START = "INSERT_FILTER_NEWSLETTER_START";
export const INSERT_FILTER_NEWSLETTER_SUCCESS =
  "INSERT_FILTER_NEWSLETTER_SUCCESS";
export const INSERT_FILTER_NEWSLETTER_FAILURE =
  "INSERT_FILTER_NEWSLETTER_FAILURE";

export const INSERT_USER_FILTER_NEWSLETTER_START =
  "INSERT_USER_FILTER_NEWSLETTER_START";
export const INSERT_USER_FILTER_NEWSLETTER_SUCCESS =
  "INSERT_USER_FILTER_NEWSLETTER_SUCCESS";
export const INSERT_USER_FILTER_NEWSLETTER_FAILURE =
  "INSERT_USER_FILTER_NEWSLETTER_FAILURE";

export const CLEAR_ERROR = "CLEAR_ERROR";
