import React from "react";

import { ReactComponent as CheckEmail } from "./checkEmail.svg";

import "./submit-offer-popup.scss";

const CheckEmailPopup = ({onClick}) => {
  return (
    <div className="check-email-popup">
      <div className="check-email-popup--inner">
        <CheckEmail />
        <h3>Congratulations!</h3>
        <p>
          Please check your email to review and sign the Assignment of Contract.
        </p>
        <button onClick={onClick}>Done</button>
      </div>
    </div>
  );
};

export default CheckEmailPopup;
