import React, { useEffect, useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import "./avatar-dropdown.scss";

import { ReactComponent as Deal } from "components/dashboard/side-navigation/deal.svg";
import { ReactComponent as OffersAndTransactions } from "components/dashboard/side-navigation/offers-and-transactions-icon-hover.svg";
import { ReactComponent as Favorites } from "components/dashboard/side-navigation/favorites-icon-hover.svg";
import { ReactComponent as ProfileAndSettings } from "components/dashboard/side-navigation/profile-icon-hover.svg";

const AvatarDropdown = withRouter(
  ({
    signOut,
    closeDropdown,
    history,
    savedNotification,
    favoriteNotification,
  }) => {
    const [notificationDotShown, setNotificationDotShown] = useState(false);

    useEffect(() => {
      if (favoriteNotification) setNotificationDotShown(true);
      if (savedNotification) setNotificationDotShown(true);
    }, [favoriteNotification, savedNotification]);

    // useEffect(() => {
    //   console.log(notificationDotShown);
    //   console.log("==============");
    // }, [notificationDotShown]);

    return (
      <div className="arrow-box" data-testid="arrow-box">
        <NavLink
          to="/dashboard/offers-and-transaction"
          onClick={() => {
            closeDropdown();
          }}
        >
          <span>
            <OffersAndTransactions />
          </span>
          Offers &amp; Transactions
        </NavLink>
        <NavLink
          to="/dashboard/favorites"
          onClick={() => {
            setNotificationDotShown(false);
            closeDropdown();
          }}
          // className={`${notificationDotShown ? "notification" : ""}`}
        >
          <span>
            <Favorites />
          </span>
          My Favorites Deals
        </NavLink>
        <NavLink
          to="/dashboard/profile-settings"
          onClick={() => {
            closeDropdown();
          }}
        >
          <span>
            <ProfileAndSettings />
          </span>
          Profile &amp; Settings
        </NavLink>
        <div
          className="log-out"
          data-testid="arrow-box-child"
          onClick={() => {
            closeDropdown();
            signOut();
            history.push("/");
          }}
        >
          Log Out
        </div>
      </div>
    );
  }
);

const mapStateToProps = ({
  listingDetail: { favoriteNotification },
  savedDeals: { savedNotification },
}) => ({
  savedNotification,
  favoriteNotification,
});

export default connect(mapStateToProps)(AvatarDropdown);
