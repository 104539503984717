export const formatFeatured = (featured) => {
  return featured.map((property) => {
    return {
      ...property,
      id: property.listing_id,
      beds: property.bedrooms,
      square_footage: property.sqft,
      lat: Number(property.latitude),
      lon: Number(property.longitude),
    };
  });
};
