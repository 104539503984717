export const FETCH_SUBMIT_OFFER_OPTIONS_START =
  "FETCH_SUBMIT_OFFER_OPTIONS_START";
export const FETCH_SUBMIT_OFFER_OPTIONS_SUCCESS =
  "FETCH_SUBMIT_OFFER_OPTIONS_SUCCESS";
export const FETCH_SUBMIT_OFFER_OPTIONS_FAILURE =
  "FETCH_SUBMIT_OFFER_OPTIONS_FAILURE";

export const POST_FORM_START = "POST_FORM_START";
export const POST_FORM_SUCCESS = "POST_FORM_SUCCESS";
export const POST_FORM_FAILURE = "POST_FORM_FAILURE";

export const CLEAR_FORM_ERROR = "CLEAR_FORM_ERROR";

export const RESET_FORM_STATUS = "RESET_FORM_STATUS";
