export const formatMapProperties = (mapItems) => {
  return mapItems.map((mapItem) => formatMapItem(mapItem));
};

export const formatMapItem = (mapItem) => {
  if (mapItem)
    return {
      ...mapItem,
      lat: mapItem.lat ? mapItem.lat : mapItem.Lat,
      lon: mapItem.lon ? mapItem.lon : mapItem.Lon,
      id: mapItem.id ? mapItem.id : mapItem.ImageId,
      address: mapItem.address ? mapItem.address : mapItem.Address,
      list_price: mapItem.list_price ? mapItem.list_price : mapItem.Sale_Price,
      images: mapItem.images ? mapItem.images : mapItem.Images,
      beds: mapItem.beds ? mapItem.beds : mapItem.Beds,
      baths: mapItem.baths ? mapItem.baths : mapItem.Baths,
      square_footage: mapItem.square_footage
        ? mapItem.square_footage
        : mapItem.Square_Feet,
    };
};
