// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:4c74f4eb-e7a0-48eb-8517-2785a7627280",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_1a8sW4STS",
  aws_user_pools_web_client_id: "1fc9fu4m4j9oscgp1k7nvr357b",
  oauth: {},
};

export default awsmobile;
