import {
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_IN_START,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_START,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  CHECK_USER_SESSION_SUCCESS,
  CHECK_USER_SESSION_FAILURE,
  CONFIRM_PASSWORD_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_ERROR,
  INSERT_USER_START,
  INSERT_USER_SUCCESS,
  INSERT_USER_FAILURE,
  INSERT_FILTER_START,
  INSERT_FILTER_SUCCESS,
  INSERT_FILTER_FAILURE,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  SIGN_UP_VERIFICATION_CODE_START,
  SIGN_UP_VERIFICATION_CODE_SUCCESS,
  SIGN_UP_VERIFICATION_CODE_FAILURE,
  CHANGE_INFO_START,
  CHANGE_INFO_SUCCESS,
  CHANGE_INFO_FAILURE,
  CHANGE_USER_CREDENTIAL,
} from "./auth.types";

const initialState = {
  loggedIn: false,
  loggingIn: false,
  currentUser: null,
  loading: false,
  errorMessage: null,
  session: null,
  userCredential: "",
  insertedUser: false,
  insertingUser: false,
  insertedFilter: false,
  insertingFilter: false,
  currentUserGroups: [],
  emailVerified: false,
  changingInfo: false,
  changingPassowrd: false,
  changingProfileImg: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_START:
    case SIGN_OUT_START:
      return {
        ...state,
        loading: true,
      };
    case SIGN_IN_START: {
      return {
        ...state,
        loggingIn: true,
      };
    }
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
        loggedIn: false,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        session: action.payload.signInUserSession,
        currentUser: action.payload.attributes.email,
        loading: false,
        loggedIn: true,
        loggingIn: false,
        userCredential: action.payload.attributes.name.slice(0, 1),
        currentUserGroups:
          action.payload.signInUserSession.accessToken.payload[
            "cognito:groups"
          ],
      };
    case SIGN_UP_FAILURE:
    case SIGN_IN_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
        loggedIn: false,
        loggingIn: false,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        loading: false,
        loggedIn: false,
        session: null,
      };

    case SIGN_OUT_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case CHANGE_PASSWORD_START:
      return {
        ...state,
        changingPassowrd: true,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        changingPassowrd: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changingPassowrd: false,
      };
    case CHECK_USER_SESSION_SUCCESS:
      return {
        ...state,
        session: action.payload,
        currentUser: action.payload.idToken.payload.email,
        userCredential: action.payload.idToken.payload.name.slice(0, 1),
        currentUserGroups: action.payload.accessToken.payload["cognito:groups"],
      };
    case CHECK_USER_SESSION_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case CONFIRM_PASSWORD_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        errorMessage: "",
      };
    case INSERT_USER_START:
      return {
        ...state,
        insertingUser: true,
      };

    case INSERT_USER_SUCCESS:
      return {
        ...state,
        insertingUser: false,
        insertedUser: true,
      };

    case INSERT_USER_FAILURE:
      return {
        ...state,
        insertingUser: false,
        insertedUser: false,
      };
    case INSERT_FILTER_START:
      return {
        ...state,
        insertingFilter: true,
      };

    case INSERT_FILTER_SUCCESS:
      return {
        ...state,
        insertingFilter: false,
        insertedFilter: true,
      };

    case INSERT_FILTER_FAILURE:
      return {
        ...state,
        insertingFilter: false,
        insertedFilter: false,
      };
    case CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        emailVerified: true,
      };
    case CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        emailVerified: false,
      };
    case SIGN_UP_VERIFICATION_CODE_START:
      return {
        ...state,
        loading: true,
      };
    case SIGN_UP_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: "",
      };
    case SIGN_UP_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case CHANGE_INFO_START:
      return {
        ...state,
        changingInfo: true,
      };
    case CHANGE_INFO_SUCCESS:
      return {
        ...state,
        changingInfo: false,
      };
    case CHANGE_INFO_FAILURE:
      return {
        ...state,
        changingInfo: false,
      };
    case CHANGE_USER_CREDENTIAL:
      return {
        ...state,
        userCredential: action.payload.slice(0, 1),
      };
    default:
      return state;
  }
};

export default authReducer;
