import {
  FETCH_SUBMIT_OFFER_OPTIONS_START,
  FETCH_SUBMIT_OFFER_OPTIONS_SUCCESS,
  FETCH_SUBMIT_OFFER_OPTIONS_FAILURE,
  POST_FORM_START,
  POST_FORM_SUCCESS,
  POST_FORM_FAILURE,
  CLEAR_FORM_ERROR,
  RESET_FORM_STATUS,
} from "./submit-offer.types";

import { store } from "../store";
import { instance } from "api/rubik";
import { Auth } from "aws-amplify";
import axios from "axios";

export const fetchSubmitOfferOptionsStart = () => ({
  type: FETCH_SUBMIT_OFFER_OPTIONS_START,
});

export const fetchSubmitOfferOptionsSuccess = (options) => ({
  type: FETCH_SUBMIT_OFFER_OPTIONS_SUCCESS,
  payload: options,
});

export const fetchSubmitOfferOptionsFailure = (error) => ({
  type: FETCH_SUBMIT_OFFER_OPTIONS_FAILURE,
  payload: error,
});

export const postFormStart = () => ({
  type: POST_FORM_START,
});

export const postFormSuccess = () => ({
  type: POST_FORM_SUCCESS,
});

export const postFormFailure = (error) => ({
  type: POST_FORM_FAILURE,
  payload: error,
});

export const clearFormError = () => ({
  type: CLEAR_FORM_ERROR,
});

export const resetFormStatus = () => ({
  type: RESET_FORM_STATUS,
});

export const startFetchingSubmitOfferOptions = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchSubmitOfferOptionsStart());

      const session = await Auth.currentSession();
      const token = session.idToken.jwtToken;

      const { data } = await instance.get(
        `https://api.rubikhomes.com/offers/v1/fields`,
        {
          headers: {
            "X-Rubik-Authorization": `Bearer ${token}`,
          },
        }
      );

      if (data) dispatch(fetchSubmitOfferOptionsSuccess(data));
    } catch (err) {
      dispatch(fetchSubmitOfferOptionsFailure(err.message));
    }
  };
};

export const submitForm = (formProps) => {
  return async (dispatch) => {
    dispatch(postFormStart());

    const property = store.getState().listingDetail.property.listing_id;

    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;

    let formatedProps = {
      ...formProps,
      proof_of_funds: formProps.proof_of_funds[0] || new File([""], ""),
      user_id: session.idToken.payload["cognito:username"],
      listing_id: property,
    };

    let formData = new FormData();

    for (let [key, value] of Object.entries(formatedProps)) {
      formData.append(`${key}`, value);
    }

    // console.log(...formData);

    try {
      const res = await axios({
        url: "https://api.rubikhomes.com/offer-service/offers",
        headers: {
          "X-Rubik-Authorization": `Bearer ${token}`,
        },
        method: "POST",
        data: formData,
      });
      // console.log(res);
      if (res.data.success) dispatch(postFormSuccess());
      return res;
    } catch (err) {
      // console.log(err.response);
      dispatch(
        postFormFailure(err.response?.data.error_message || err.message)
      );
    }
  };
};
