export const formatSavedDeals = (deals) => {
  return deals.map(({ listing: deal }) => {
    return {
      ...deal,
      address: deal.address,
      arv: deal.arv,
      baths: deal.baths,
      beds: deal.bedrooms,
      first_image: deal.images[0],
      id: deal.listing_id,
      images: deal.images,
      lat: Number(deal.latitude),
      lon: Number(deal.longitude),
      square_footage: deal.sqft,
      time_left: deal.bid_deadline,
    };
  });
};
