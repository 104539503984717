export const formatOffers = (offers) => {
  return offers.map((offer) => {
    const { listing } = offer;
    return {
      ...listing,
      offer_status: offer.status,
      address: listing.address,
      arv: listing.arv,
      baths: listing.baths,
      beds: listing.bedrooms,
      first_image: listing.images[0],
      id: listing.listing_id,
      images: listing.images,
      lat: Number(listing.latitude),
      lon: Number(listing.longitude),
      square_footage: listing.sqft,
      time_left: listing.bid_deadline,
    };
  });
};
