import {
  FETCH_SUBMIT_OFFER_OPTIONS_START,
  FETCH_SUBMIT_OFFER_OPTIONS_SUCCESS,
  FETCH_SUBMIT_OFFER_OPTIONS_FAILURE,
  POST_FORM_START,
  POST_FORM_SUCCESS,
  POST_FORM_FAILURE,
  CLEAR_FORM_ERROR,
  RESET_FORM_STATUS,
} from "./submit-offer.types";

const initialState = {
  options: [],
  isFetching: false,
  error: null,
  submitingForm: false,
  submitedSuccesfuly: false,
  submitFormError: null,
};

const submitOfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBMIT_OFFER_OPTIONS_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUBMIT_OFFER_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.payload,
        isFetching: false,
      };
    case FETCH_SUBMIT_OFFER_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case POST_FORM_START:
      return {
        ...state,
        submitingForm: true,
        submitFormError: "",
      };
    case POST_FORM_SUCCESS:
      return {
        ...state,
        submitedSuccesfuly: true,
        submitingForm: false,
        submitFormError: "",
      };
    case POST_FORM_FAILURE:
      return {
        ...state,
        submitedSuccesfuly: false,
        submitingForm: false,
        submitFormError: action.payload,
      };
    case CLEAR_FORM_ERROR:
      return {
        ...state,
        submitFormError: null,
      };
    case RESET_FORM_STATUS:
      return {
        ...state,
        submitingForm: false,
        submitedSuccesfuly: false,
        submitFormError: null,
      };
    default:
      return state;
  }
};

export default submitOfferReducer;
