import React from "react";
import "./input-field.scss";

function InputField({
  id,
  label,
  onChange,
  placeholder,
  type,
  name,
  required,
  onPaste,
}) {
  return (
    <div className="input-field">
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type={type ? type : "text"}
        onChange={onChange}
        placeholder={placeholder}
        required={required ? required : null}
        name={name}
        onPaste={onPaste ? onPaste : () => {}}
        onFocus={(e) => {
          e.currentTarget.parentElement.style.border = "1px solid #3369E2";
          e.currentTarget.parentElement.style.backgroundColor = "#fff";
          e.currentTarget.parentElement.classList.remove("input-error");
          e.currentTarget.previousElementSibling.style.top = "-10px";
          e.currentTarget.previousElementSibling.style.left = "2px";
          e.currentTarget.previousElementSibling.style.fontSize = "14px";
          e.currentTarget.previousElementSibling.style.color = "#3369E2";
          e.currentTarget.previousElementSibling.style.fontWeight = "500";
          e.currentTarget.previousElementSibling.style.backgroundColor = "#fff";
          e.currentTarget.parentElement.parentElement.style.top = "0px";
          // e.currentTarget.parentElement.parentElement.style.bottom = "0px";
        }}
        onBlur={(e) => {
          if (e.currentTarget.value.length == 0) {
            e.currentTarget.parentElement.style.border =
              "1px solid transparent";
            e.currentTarget.parentElement.style.backgroundColor = "#f6f9ff";
            e.currentTarget.previousElementSibling.style.top = "17px";
            e.currentTarget.previousElementSibling.style.left = "10px";
            e.currentTarget.previousElementSibling.style.fontSize = "17px";
            e.currentTarget.previousElementSibling.style.color = "gray";
            e.currentTarget.previousElementSibling.style.backgroundColor =
              "transparent";
            e.currentTarget.previousElementSibling.style.fontWeight = "400";
          }
          // e.currentTarget.parentElement.parentElement.style.bottom = "100px";
        }}
      />
    </div>
  );
}

export default InputField;
