import {
  FETCH_SINGLE_PROPERTY_START,
  FETCH_SINGLE_PROPERTY_SUCCESS,
  FETCH_SINGLE_PROPERTY_FAILURE,
  TOGGLE_SINGLE_DEAL_STATUS,
  CLEAR_PROPERTY,
  FETCH_FAVORITE_STATUS_FAILURE,
  FETCH_FAVORITE_STATUS_SUCCESS,
  SET_ACQUISITION_TEAM,
} from "./listing-detail.types";

import {
  formatProperty,
  formatComp,
  averageRow,
  medianRow,
  calculateSqftMedian,
  formatMapItem,
  formatAcquisitionTeam,
} from "./listing-details.utilis";

const initialState = {
  property: {},
  propertyDetails: {},
  formatedProperty: {},
  isFetching: false,
  error: null,
  isFavorite: null,
  isFavoriteErr: null,
  favoriteNotification: false,
  acquisitionTeam: [],
};

const listingDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SINGLE_PROPERTY_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SINGLE_PROPERTY_SUCCESS:
      return {
        ...state,
        property: action.payload,
        propertyDetails: formatMapItem(action.payload),
        isFetching: false,
      };
    case FETCH_SINGLE_PROPERTY_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case CLEAR_PROPERTY:
      return {
        ...state,
        property: {},
        propertyDetails: {},
        formatedProperty: {},
      };
    case TOGGLE_SINGLE_DEAL_STATUS:
      return {
        ...state,
        isFavorite: !state.isFavorite,
        favoriteNotification: !state.isFavorite ? true : false,
      };
    case FETCH_FAVORITE_STATUS_SUCCESS:
      return {
        ...state,
        isFavorite: action.payload,
      };
    case FETCH_FAVORITE_STATUS_FAILURE:
      return {
        ...state,
        isFavoriteErr: action.payload,
      };
    case SET_ACQUISITION_TEAM:
      return {
        ...state,
        acquisitionTeam: formatAcquisitionTeam(action.payload),
      };
    default:
      return state;
  }
};

export default listingDetailReducer;
