import {
  TOGGLE_SIGN_IN_POPUP,
  TOGGLE_SIGN_UP_POPUP,
  SWITCH_TO_SIGN_IN,
  SWITCH_TO_SIGN_UP,
  TOGGLE_FORGOT_PASSWORD_POPUP,
  TOGGLE_VERIFICATION_CODE_POPUP,
  TOGGLE_RESET_PASSWORD_POPUP,
  TOGGLE_MOBILE_MENU,
  CLOSE_ALL_POPUPS,
  TOGGLE_QUESTIONS_POPUP,
  SWITCH_TO_QUESTIONS,
  SET_EMAIL,
  SET_VERIFICATION_CODE,
  TOGGLE_SCHEDULECALL_POPUP,
  TOGGLE_SUBMIT_FORM,
  TOGGLE_SIGN_UP_VERIFICATION_CODE,
  TOGGLE_CONFIRM_EMAIL_POPUP,
  TOOGLE_CHECK_EMAIL_POPUP,
} from "./popup.types";

export const toggleSignInPopup = (redirectPath) => ({
  type: TOGGLE_SIGN_IN_POPUP,
  payload: redirectPath,
});

export const toggleSignUpPopup = () => ({
  type: TOGGLE_SIGN_UP_POPUP,
});

export const toggleForgotPasswordPopup = () => ({
  type: TOGGLE_FORGOT_PASSWORD_POPUP,
});

export const toggleVerificationCodePopup = () => ({
  type: TOGGLE_VERIFICATION_CODE_POPUP,
});

export const toggleResetPasswordPopup = () => ({
  type: TOGGLE_RESET_PASSWORD_POPUP,
});

export const toggleMobileMenu = () => ({
  type: TOGGLE_MOBILE_MENU,
});

export const closeAllPopups = () => ({
  type: CLOSE_ALL_POPUPS,
});

export const switchToSignIn = () => ({
  type: SWITCH_TO_SIGN_IN,
});

export const switchToQuestions = () => ({
  type: SWITCH_TO_QUESTIONS,
});

export const switchToSignUp = () => ({
  type: SWITCH_TO_SIGN_UP,
});

export const toggleQuestionsPopup = () => ({
  type: TOGGLE_QUESTIONS_POPUP,
});

export const setEmail = (email) => ({
  type: SET_EMAIL,
  payload: email,
});

export const setVerificationCode = (code) => ({
  type: SET_VERIFICATION_CODE,
  payload: code,
});

export const toggleScheduleCallPopup = () => ({
  type: TOGGLE_SCHEDULECALL_POPUP,
});

export const toggleSubmitForm = () => ({
  type: TOGGLE_SUBMIT_FORM,
});

export const toggleSignupVerificationCodePopup = () => ({
  type: TOGGLE_SIGN_UP_VERIFICATION_CODE,
});

export const toggleConfirmEmailPopup = () => ({
  type: TOGGLE_CONFIRM_EMAIL_POPUP,
});

export const toggleCheckEmailPopup = () => ({
  type: TOOGLE_CHECK_EMAIL_POPUP,
});
