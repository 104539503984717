import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Avatar from "../avatar/avatar";
import AvatarDropdown from "../avatar-dropdown/avatar-dropdown";

import { signOut } from "redux/auth/auth.actions";
import {
  toggleSignInPopup,
  toggleSignUpPopup,
  toggleMobileMenu,
  toggleScheduleCallPopup,
  toggleSignupVerificationCodePopup,
} from "redux/popup/popup.actions";

import { MixpanelConsumer } from "react-mixpanel";
import { useWindowDimensions } from "hooks/useWindowDimensions";

const Navigation = withRouter(
  ({
    signOut,
    toggleSignInPopup,
    toggleSignUpPopup,
    isMobileMenu,
    currentUser,
    toggleMobileMenu,
    location,
    userCredential,
    toggleScheduleCallPopup,
    toggleSignupVerificationCodePopup,
  }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { width } = useWindowDimensions();

    const onClickHandler = (path) => {
      toggleMobileMenu();
      if (!currentUser) {
        toggleSignInPopup(path);
      }
    };

    const closeDropdown = () => setIsDropdownOpen(false);

    useEffect(() => {
      if (isDropdownOpen && width > 767) {
        window.addEventListener("click", closeDropdown);
      }
      return () => window.removeEventListener("click", closeDropdown);
    });

    return (
      <MixpanelConsumer>
        {(mixpanel) => (
          <>
            <div
              className={`hamburger-icon  ${isMobileMenu ? "is-active" : ""}`}
              onClick={() => {
                mixpanel.track("Pressed the Hamburger icon on mobile");
                toggleMobileMenu();
              }}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
            <div className={`navigation ${isMobileMenu ? "is-opened" : ""}`}>
              <ul>
                <li>
                  <NavLink
                    onClick={() => {
                      mixpanel.track(
                        "Pressed the home link on navigation on header"
                      );
                      toggleMobileMenu();
                    }}
                    to="/"
                    exact
                    activeClassName={currentUser ? "active" : ""}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <a
                    onClick={() => {
                      mixpanel.track(
                        "Pressed the facetime link on navigation on header"
                      );
                      toggleMobileMenu();
                    }}
                    href={`tel:(972) 236 7359`}
                    className=""
                  >
                    (972) 236 7359
                  </a>
                </li>
                <li>
                  <NavLink
                    onClick={() => {
                      mixpanel.track(
                        "Pressed the search link on navigation on header"
                      );
                      onClickHandler("/search/page/1?state=Texas");
                    }}
                    to={
                      currentUser
                        ? `/search/page/1?state=Texas`
                        : location.pathname
                    }
                    activeClassName={currentUser ? "active" : ""}
                  >
                    Search
                  </NavLink>
                </li>

                {/* <li>
                  <NavLink
                    onClick={() => {
                      mixpanel.track(
                        "Pressed the my deals link on navigation on header"
                      );
                      onClickHandler("/search/deals");
                    }}
                    to={currentUser ? `/search/deals` : location.pathname}
                    activeClassName={currentUser ? "active" : ""}
                  >
                    My Deals
                  </NavLink>
                </li> */}

                <li>
                  <NavLink
                    onClick={toggleMobileMenu}
                    to={`/about`}
                    activeClassName="active"
                  >
                    About
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={toggleMobileMenu}
                    to={`/faq`}
                    activeClassName="active"
                  >
                    Faq
                  </NavLink>
                </li>

                <div className="logout-mobile">
                  {/* <li>
                    <NavLink
                      onClick={toggleMobileMenu}
                      to={`/userprofile`}
                      activeclass="active"
                    >
                      Profile
                    </NavLink>
                  </li> */}
                  <li></li>

                  {currentUser && (
                    <li>
                      <NavLink
                        onClick={() => {
                          mixpanel.track("Pressed the sign out button");
                          toggleMobileMenu();
                          signOut();
                        }}
                        to={`/`}
                        activeclass="active"
                      >
                        Sign Out
                      </NavLink>
                    </li>
                  )}
                </div>
              </ul>
              {!currentUser && (
                <div className="cta-buttons">
                  <button
                    className="button button--light login-btn"
                    onClick={() => {
                      mixpanel.track("Pressed the Login button on Header");
                      toggleSignInPopup("/");
                    }}
                  >
                    Log in
                  </button>
                  <button
                    className="button button--default"
                    onClick={() => {
                      mixpanel.track(
                        "Pressed the Get Started button on Header"
                      );
                      toggleSignupVerificationCodePopup();
                    }}
                  >
                    Register
                  </button>
                </div>
              )}
            </div>
            {currentUser && (
              <div
                className={`user-avatar ${isDropdownOpen ? "is-dropdown" : ""}`}
              >
                <Avatar
                  userCredential={userCredential}
                  onClick={(event) => {
                    mixpanel.track("Pressed the Avatar on Header");
                    event.stopPropagation();
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                />

                {isDropdownOpen && (
                  <AvatarDropdown
                    closeDropdown={() => setIsDropdownOpen(false)}
                    signOut={signOut}
                  />
                )}
              </div>
            )}
          </>
        )}
      </MixpanelConsumer>
    );
  }
);

const mapStateToProps = ({
  auth: { currentUser, userCredential },
  popups: { mobileMenuActive },
}) => ({
  isMobileMenu: mobileMenuActive,
  currentUser,
  userCredential,
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
  toggleSignInPopup: (redirectPath) =>
    dispatch(toggleSignInPopup(redirectPath)),
  toggleSignUpPopup: () => dispatch(toggleSignUpPopup()),
  toggleMobileMenu: () => dispatch(toggleMobileMenu()),
  toggleScheduleCallPopup: () => dispatch(toggleScheduleCallPopup()),
  toggleSignupVerificationCodePopup: () =>
    dispatch(toggleSignupVerificationCodePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
