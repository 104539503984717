import React, { useState } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { ReactComponent as Logo } from "../logo.svg";

import { toggleQuestionsPopup } from "redux/popup/popup.actions";
import {
  postQuestions,
  checkQuestionsStatus,
} from "redux/questions/questions.actions";

import "../popup.scss";
import "./questions-popup.scss";

const QuestionsPopup = ({
  isPopupVisible,
  toggleQuestionsPopup,
  questions,
  postQuestions,
  currentUser,
  checkQuestionsStatus,
  isAnsweringQuestions,
  mixpanel,
}) => {
  const [selectedQuestions, setSelectedQuestions] = useState({
    question1: { question_id: "", question_option_id: "" },
    question2: { question_id: "", question_option_id: "" },
    question3: { question_id: "", question_option_id: "" },
    question4: { question_id: "", question_option_id: "" },
  });

  const handleOptionsChange = (event, questionId) => {
    setSelectedQuestions({
      ...selectedQuestions,
      [event.target.name]: {
        question_id: questionId,
        question_option_id: event.target.value,
      },
    });
  };

  const { question1, question2, question3, question4 } = selectedQuestions;

  const handleSubmit = async () => {
    if (
      !question1.question_option_id ||
      !question2.question_option_id ||
      !question3.question_option_id ||
      !question4.question_option_id
    ) {
      return;
    }

    mixpanel.track("Submited questions in questios popup");
    let response = await postQuestions(
      currentUser,
      Object.values(selectedQuestions)
    );

    if (response) toggleQuestionsPopup();
  };

  return (
    <div
      className={`popup questions-popup ${isPopupVisible ? "is-active" : ""}`}
    >
      <div className="popup-form">
        <div className="logo">
          <Logo />
        </div>

        {questions &&
          questions.map((singleQuestion) => {
            const { id, question, options } = singleQuestion;

            return (
              <div className="select-holder" key={id}>
                <TextField
                  className="field"
                  id="outlined-secondary"
                  select
                  label={question}
                  onChange={(e) => handleOptionsChange(e, id)}
                  value={selectedQuestions[`question${id}`].question_option_id}
                  variant={"outlined"}
                  name={`question${id}`}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.optionId}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            );
          })}

        <div className="from-input">
          <button
            className="button button--default"
            onClick={handleSubmit}
            disabled={isAnsweringQuestions ? true : false}
          >
            {isAnsweringQuestions ? "SUBMITTING..." : "SUBMIT"}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  popups: { questionsPopupshown },
  auth: { currentUser },
  questions: { questions, isAnsweringQuestions },
}) => ({
  isPopupVisible: questionsPopupshown,
  questions,
  currentUser,
  isAnsweringQuestions,
});

const mapDispatchToProps = (dispatch) => ({
  toggleQuestionsPopup: () => dispatch(toggleQuestionsPopup()),
  postQuestions: (email, questions) =>
    dispatch(postQuestions({ email, questions })),
  checkQuestionsStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsPopup);
