import React, { useState } from "react";
import { connect } from "react-redux";

import { ReactComponent as Logo } from "../logo.svg";

import {
  toggleVerificationCodePopup,
  toggleResetPasswordPopup,
  setVerificationCode,
} from "redux/popup/popup.actions";

const VerificationCode = ({
  email,
  isPopupVisible,
  toggleVerificationCodePopup,
  toggleResetPasswordPopup,
  setVerificationCode,
}) => {
  const submitCode = (e) => {
    e.preventDefault();
    if (input) {
      setVerificationCode(input);
      toggleVerificationCodePopup();
      toggleResetPasswordPopup();
      setInput("");
    }
  };

  const [input, setInput] = useState("");

  const changeInput = (e) => {
    setInput(e.target.value);
  };

  return (
    <div className={`popup ${isPopupVisible ? "is-active" : ""}`}>
      <div className="popup-form login-form">
        <div className="logo">
          <Logo />
        </div>
        <div className="form-holder">
          <p>
            A verification code is send to <span>{email} .</span> Please check
            your email for validation code.
          </p>
          <form onSubmit={submitCode}>
            <div className="form-input">
              <input
                type="text"
                placeholder="Please enter the code"
                name="enter-code"
                id="reset-email"
                value={input}
                onChange={changeInput}
              />
            </div>
            <div className="form-input">
              <button className="button button--default">Continue</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  popups: { enterCodePopupShown, email },
  auth: { loading },
}) => ({
  isPopupVisible: enterCodePopupShown,
  email,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  toggleVerificationCodePopup: () => dispatch(toggleVerificationCodePopup()),
  toggleResetPasswordPopup: () => dispatch(toggleResetPasswordPopup()),
  setVerificationCode: (code) => dispatch(setVerificationCode(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationCode);
