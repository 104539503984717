import {
  FETCH_PROPERTIES_LIST_START,
  FETCH_PROPERTIES_LIST_SUCCESS,
  FETCH_PROPERTIES_LIST_FAILURE,
  FETCH_SEARCH_AUTOCOMPLETE_START,
  FETCH_SEARCH_AUTOCOMPLETE_SUCCESS,
  FETCH_SEARCH_AUTOCOMPLETE_FAILURE,
  TOGGLE_MORE_FILTERS,
  HANDLE_LIST_VIEW,
  HANDLE_THUMBLIST_VIEW,
  FOLD_LIST_VIEW,
  TOGGLE_FULL_MAP,
  SET_FILTER_VALUES,
  CLEAR_FILTERS,
} from "./search-property.types";

import {
  FAVORITE_DEAL_SUCCESS,
  UNFAVORITE_DEAL_SUCCESS_FROM_LIST,
} from "../saved-deals/saved-deals.types";

import { formatProperties } from "./search-property.util";

const initialState = {
  listProperties: [],
  isFetching: false,
  error: null,
  searchResult: [],
  isFetchingAutoComplete: false,
  searchError: null,
  totalPages: 0,
  totalResults: 0,
  defaultView: true,
  moreFilters: false,
  isFolded: false,
  fullMap: false,
  filterValues: {
    status: "",
    bidDeadline: "",
    type: "",
  },
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_LIST_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PROPERTIES_LIST_SUCCESS:
      return {
        ...state,
        listProperties: formatProperties(action.payload.results),
        totalPages: action.payload.page.total_pages,
        totalResults: action.payload.page.total_elements,
        isFetching: false,
      };
    case FETCH_PROPERTIES_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_SEARCH_AUTOCOMPLETE_START:
      return {
        ...state,
        isFetchingAutoComplete: true,
      };
    case FETCH_SEARCH_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        searchResult: action.payload,
        isFetchingAutoComplete: false,
      };
    case FETCH_SEARCH_AUTOCOMPLETE_FAILURE:
      return {
        ...state,
        isFetchingAutoComplete: false,
        searchError: action.payload,
      };
    case HANDLE_THUMBLIST_VIEW:
      return {
        ...state,
        defaultView: true,
      };
    case HANDLE_LIST_VIEW:
      return {
        ...state,
        defaultView: false,
      };
    case TOGGLE_MORE_FILTERS:
      return {
        ...state,
        moreFilters: !state.moreFilters,
      };
    case SET_FILTER_VALUES:
      return {
        ...state,
        filterValues: action.payload,
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        filterValues: {
          status: "",
          bidDeadline: "",
          type: "",
        },
      };
    case FOLD_LIST_VIEW:
      return {
        ...state,
        isFolded: !state.isFolded,
        defaultView: true,
        // moreFilters: false,
      };
    case TOGGLE_FULL_MAP:
      return {
        ...state,
        fullMap: !state.fullMap,
      };
    case FAVORITE_DEAL_SUCCESS:
      return {
        ...state,
        listProperties: state.listProperties.map((item) => {
          if (item.id === action.payload) {
            return Object.assign({}, item, { favorite: true });
          }
          return item;
        }),
      };
    case UNFAVORITE_DEAL_SUCCESS_FROM_LIST:
      return {
        ...state,
        listProperties: state.listProperties.map((item) => {
          if (item.id === action.payload) {
            return Object.assign({}, item, { favorite: false });
          }
          return item;
        }),
      };
    default:
      return state;
  }
};

export default searchReducer;
