export const TOGGLE_SIGN_IN_POPUP = "TOGGLE_SIGN_IN_POPUP";
export const TOGGLE_SIGN_UP_POPUP = "TOGGLE_SIGN_UP_POPUP";
export const TOGGLE_FORGOT_PASSWORD_POPUP = "TOGGLE_FORGOT_PASSWORD_POPUP";
export const TOGGLE_VERIFICATION_CODE_POPUP = "TOGGLE_VERIFICATION_CODE_POPUP";
export const TOGGLE_RESET_PASSWORD_POPUP = "TOGGLE_RESET_PASSWORD_POPUP";
export const TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU";
export const TOGGLE_QUESTIONS_POPUP = "TOGGLE_QUESTIONS_POPUP";

export const CLOSE_ALL_POPUPS = "CLOSE_ALL_POPUPS";

export const SWITCH_TO_SIGN_IN = "SWITCH_TO_SIGN_IN";
export const SWITCH_TO_SIGN_UP = "SWITCH_TO_SIGN_UP";

export const TOGGLE_CONFIRM_EMAIL_POPUP = "TOGGLE_CONFIRM_EMAIL_POPUP";

export const TOGGLE_SIGN_UP_VERIFICATION_CODE =
  "TOGGLE_SIGN_UP_VERIFICATION_CODE";

export const OPEN_COMPS_MODAL = "OPEN_COMPS_MODAL";
export const CLOSE_COMPS_MODAL = "CLOSE_COMPS_MODAL";

export const SWITCH_TO_QUESTIONS = "SWITCH_TO_QUESTIONS";

export const SET_EMAIL = "SET_EMAIL";
export const SET_VERIFICATION_CODE = "SET_VERIFICATION_CODE";

export const TOGGLE_HOME_PIC_POPUP = "TOGGLE_HOME_PIC_POPUP";

export const TOGGLE_SCHEDULECALL_POPUP = "TOGGLE_SCHEDULECALL_POPUP";

export const TOGGLE_SUBMIT_FORM = "TOGGLE_SUBMIT_FORM";

export const TOOGLE_CHECK_EMAIL_POPUP = "TOOGLE_CHECK_EMAIL_POPUP";
