import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";

import rootReducer from "./root-reducer";

// const saveToLocalStorage = (state) => {
// 	try {
// 		const serializeState = JSON.stringify(state);
// 		localStorage.setItem("state", serializeState);
// 	} catch (e) {
// 		console.log(e);
// 	}
// };

// const loadFromLocalStorage = () => {
// 	try {
// 		const serializedState = localStorage.getItem("state");
// 		if (serializedState === null) {
// 			return undefined;
// 		}
// 		return JSON.parse(serializedState);
// 	} catch (e) {
// 		console.log(e);
// 		return undefined;
// 	}
// };

// const persistedState = loadFromLocalStorage();

const store = createStore(rootReducer, applyMiddleware(thunk));

const persistor = persistStore(store);

// persistedState,
// window.__REDUX_DEVTOOLS_EXTENSION__ &&
// 	window.__REDUX_DEVTOOLS_EXTENSION__(),

// store.subscribe(() => saveToLocalStorage(store.getState()));

export { store, persistor };
