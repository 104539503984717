import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import classnames from "classnames";

import { ReactComponent as Logo } from "assets/svg/logo.svg";
import Navigation from "../navigation/navigation";

import { useWindowScrollPosition } from "hooks/useWindowScrollPosition";

import { MixpanelConsumer } from "react-mixpanel";

const Header = () => {
  const { pathname } = useLocation();
  const windowScrollPosition = useWindowScrollPosition();

  let headerClasses = classnames({
    header: true,
    // "transparent-header": windowScrollPosition < 10 && pathname === "/",
    // "blue-header": windowScrollPosition === 0 && pathname === "/about",
    "extended-header": pathname.startsWith("/search"),
  });

  return (
    <header className={headerClasses} data-testid="header">
      <div className="container">
        <div className="header__inner">
          <MixpanelConsumer>
            {(mixpanel) => (
              <NavLink
                to="/"
                onClick={() => mixpanel.track("Pressed Logo on Header")}
              >
                <div className="logo">
                  <Logo />
                </div>
              </NavLink>
            )}
          </MixpanelConsumer>

          <Navigation />
        </div>
      </div>
    </header>
  );
};

export default Header;
