import {
  FETCH_QUESTIONS_START,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  POST_QUESTION_START,
  POST_QUESTION_SUCCESS,
  POST_QUESTION_FAILURE,
  CHECK_IF_ANSWERED_QUESTIONS,
} from "./questions.types";
import axios from "axios";
import { Auth } from "aws-amplify";

export const fetchQuestionsStart = () => ({
  type: FETCH_QUESTIONS_START,
});

export const fetchQuestionsSuccess = (questions) => ({
  type: FETCH_QUESTIONS_SUCCESS,
  payload: questions,
});

export const fetchQuestionsFailure = (error) => ({
  type: FETCH_QUESTIONS_FAILURE,
  payload: error,
});

export const postQuestionStart = () => ({
  type: POST_QUESTION_START,
});

export const postQuestionSuccess = () => ({
  type: POST_QUESTION_SUCCESS,
});

export const postQuestionFailure = () => ({
  type: POST_QUESTION_FAILURE,
});

export const checkAnsweredQuest = (answered) => ({
  type: CHECK_IF_ANSWERED_QUESTIONS,
  payload: answered,
});

export const checkQuestionsStatus = () => {
  return async (dispatch) => {
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;

    fetch(`https://api.rubikhomes.com/questions/v1/check`, {
      method: "post",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
        "X-Rubik-Authorization": `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(checkAnsweredQuest(data.answered));
      })
      .then((error) => {
        // console.log(error);
      });
  };
};

export const startFetchingQuestionsAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchQuestionsStart());
      const { data } = await axios.get(
        `https://api.rubikhomes.com/questions/v1/options`
      );
      dispatch(fetchQuestionsSuccess(data));
    } catch (err) {
      dispatch(fetchQuestionsFailure(err.message));
    }
  };
};

export const postQuestions = ({ email, questions }) => {
  return async (dispatch) => {
    try {
      dispatch(postQuestionStart());

      const session = await Auth.currentSession();
      const token = session.idToken.jwtToken;

      let response = await fetch(`https://api.rubikhomes.com/questions/v1`, {
        method: "post",
        body: JSON.stringify({
          email,
          question: questions,
        }),
        headers: {
          "Content-Type": "application/json",
          "X-Rubik-Authorization": `Bearer ${token}`,
        },
      });
      let data = await response.json();
      if (data) {
        dispatch(postQuestionSuccess());
        return data.success;
      }
    } catch (err) {
      dispatch(postQuestionFailure());
    }
  };
};
