import React from "react";
import { formatNumber } from "utilis/formatNumber";

import "./list-home-details.scss";

const ListHomeDetails = ({ beds, baths, sqft, className }) => {
  return (
    <ul className={`list-home-details ${className}`}>
      <li>
        <span className="property-room-value bedroom">{beds} bd</span>
      </li>
      <li>
        <span className="property-room-value bathroom">
          {baths % 1 === 0 ? parseInt(baths) : baths}
          ba
        </span>
      </li>
      <li>
        <span className="property-room-value feetsquare">
          {formatNumber(sqft)} ft<sup>2</sup>
        </span>
      </li>
    </ul>
  );
};

export default ListHomeDetails;
