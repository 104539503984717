import {
  SIGN_UP_START,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_IN_START,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_START,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  CHECK_USER_SESSION_SUCCESS,
  CHECK_USER_SESSION_FAILURE,
  CONFIRM_PASSWORD_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_ERROR,
  INSERT_USER_START,
  INSERT_USER_SUCCESS,
  INSERT_USER_FAILURE,
  INSERT_FILTER_START,
  INSERT_FILTER_SUCCESS,
  INSERT_FILTER_FAILURE,
  INSERT_USER_FILTER_START,
  INSERT_USER_FILTER_SUCCESS,
  INSERT_USER_FILTER_FAILURE,
  CHANGE_INFO_START,
  CHANGE_INFO_SUCCESS,
  CHANGE_INFO_FAILURE,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  SIGN_UP_VERIFICATION_CODE_START,
  SIGN_UP_VERIFICATION_CODE_SUCCESS,
  SIGN_UP_VERIFICATION_CODE_FAILURE,
  CHANGE_USER_CREDENTIAL,
} from "./auth.types";

import { Auth } from "aws-amplify";
import { instance } from "api/rubik";
import axios from "axios";

import {
  insertUserNewsletterStart,
  insertUserNewsletterSuccess,
  insertUserNewsletterFailure,
  insertFilterNewsletterStart,
  insertFilterNewsletterSuccess,
  insertFilterNewsletterFailure,
  // insertUserFilterNewsletterStart,
  // insertUserFilterNewsletterSuccess,
  // insertUserFilterNewsletterFailure,
} from "../newsletter/newsletter.actions";
import {
  switchToSignIn,
  switchToSignUp,
  toggleResetPasswordPopup,
  toggleForgotPasswordPopup,
  toggleVerificationCodePopup,
  toggleConfirmEmailPopup,
  toggleCheckEmailPopup,
} from "../popup/popup.actions";

export const signUpStart = () => ({
  type: SIGN_UP_START,
});

export const signUpSuccess = (message) => ({
  type: SIGN_UP_SUCCESS,
  message: message,
});

export const signUpFailure = (error) => ({
  type: SIGN_UP_FAILURE,
  payload: error,
});

export const signInStart = () => ({
  type: SIGN_IN_START,
});

export const signInSuccess = (user) => ({
  type: SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailure = (error) => ({
  type: SIGN_IN_FAILURE,
  payload: error,
});

export const signOutStart = () => ({
  type: SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: SIGN_OUT_FAILURE,
  payload: error,
});

export const changePasswordStart = () => ({
  type: CHANGE_PASSWORD_START,
});

export const changePasswordFailure = (error) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload: error,
});

export const changePasswordSuccess = (msg) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: msg,
});

export const checkUserSessionSuccess = (session) => ({
  type: CHECK_USER_SESSION_SUCCESS,
  payload: session,
});

export const checkUserSessionFailure = (error) => ({
  type: CHECK_USER_SESSION_FAILURE,
  payload: error,
});

export const confirmPasswordFailure = (error) => ({
  type: CONFIRM_PASSWORD_FAILURE,
  payload: error,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const insertUserStart = () => ({
  type: INSERT_USER_START,
});

export const insertUserSuccess = () => ({
  type: INSERT_USER_SUCCESS,
});

export const insertUserFailure = () => ({
  type: INSERT_USER_FAILURE,
});

export const insertFilterStart = () => ({
  type: INSERT_FILTER_START,
});

export const insertFilterSuccess = () => ({
  type: INSERT_FILTER_SUCCESS,
});

export const insertFilterFailure = () => ({
  type: INSERT_FILTER_FAILURE,
});

export const insertUserFilterStart = () => ({
  type: INSERT_USER_FILTER_START,
});

export const insertUserFilterSuccess = () => ({
  type: INSERT_USER_FILTER_SUCCESS,
});

export const insertUserFilterFailure = () => ({
  type: INSERT_USER_FILTER_FAILURE,
});

export const changeInfoStart = () => ({
  type: CHANGE_INFO_START,
});

export const changeInfoSuccess = () => ({
  type: CHANGE_INFO_SUCCESS,
});

export const changeInfoFailure = (err) => ({
  type: CHANGE_INFO_FAILURE,
  payload: err,
});

export const confirmEmailSuccess = () => ({
  type: CONFIRM_EMAIL_SUCCESS,
});

export const confirmEmailFailure = () => ({
  type: CONFIRM_EMAIL_FAILURE,
});

export const signupVerificationCodeStart = () => ({
  type: SIGN_UP_VERIFICATION_CODE_START,
});

export const signupVerificationCodeSuccess = () => ({
  type: SIGN_UP_VERIFICATION_CODE_SUCCESS,
});

export const signupVerificationCodeFailure = (message) => ({
  type: SIGN_UP_VERIFICATION_CODE_FAILURE,
  payload: message,
});

export const changeUserCredential = (user) => ({
  type: CHANGE_USER_CREDENTIAL,
  payload: user,
});

export const insertUserFilter = ({ filter, email }) => {
  return async (dispatch) => {
    try {
      dispatch(insertUserFilterStart());

      let response = await fetch(
        "https://api.rubikhomes.com/remarketing/insert_user_filter",
        {
          body: JSON.stringify({
            Filter: filter,
            Email: email,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let data = await response.json();
      if (!data) return;

      dispatch(insertUserFilterSuccess());
      return data;
    } catch (err) {
      dispatch(insertUserFilterFailure());
    }
  };
};

export const insertFilter = (isNewsletter) => {
  return async (dispatch) => {
    try {
      if (isNewsletter) dispatch(insertFilterNewsletterStart());
      else dispatch(insertFilterStart());

      let response = await fetch(
        "https://api.rubikhomes.com/remarketing/insert_filter",
        {
          body: JSON.stringify({}),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let data = await response.json();
      if (!data) return;

      if (isNewsletter) dispatch(insertFilterNewsletterSuccess());
      else dispatch(insertFilterSuccess());

      return data;
    } catch (err) {
      if (isNewsletter) dispatch(insertFilterNewsletterFailure());
      else dispatch(insertFilterFailure());
    }
  };
};

export const insertUser = (
  { firstname, lastname, phone, email },
  newsletter
) => {
  return async (dispatch) => {
    try {
      if (newsletter) dispatch(insertUserNewsletterStart());
      else dispatch(insertUserStart());

      let response = await fetch(
        "https://api.rubikhomes.com/remarketing/insert_user",
        {
          body: JSON.stringify({
            FirstName: firstname,
            LastName: lastname,
            PhoneNumber: phone,
            Email: email,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (newsletter) dispatch(insertUserNewsletterSuccess());
      else dispatch(insertUserSuccess());

      if (response.status === 400) {
        if (newsletter)
          dispatch(
            insertUserNewsletterFailure("Already signed up for the newsletter.")
          );
        else dispatch(insertUserFailure);
      }

      return response;
    } catch (err) {}
  };
};

export const signUp = ({ name, lastname, email, password, phone }) => {
  return async (dispatch) => {
    try {
      dispatch(signUpStart());

      let signUpResponse = await instance({
        url: "https://api.rubikhomes.com/user-service/user/signup",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          username: email,
          email: email,
          password,
          name: name + " " + lastname,
          phone_number: phone,
          confirm: true,
        },
      });

      dispatch(toggleCheckEmailPopup());

      return signUpResponse;
    } catch (err) {
      // console.log(err.response.data.message);
      if (err.response.data.message === "This username already exists") {
        dispatch(
          signUpFailure(
            "This email address is already taken. Please try another"
          )
        );
      }
      // else if (
      //   err.response.data.message ===
      //   "An error occurred (InvalidParameterException) when calling the SignUp operation: Invalid phone number format."
      // ) {
      //   dispatch(
      //     signUpFailure(
      //       "Please put a + sign before the phone number and dont leave spaces in the number"
      //     )
      //   );
      // }
      else if (
        err.response.data.message ===
        "Password should have Caps,Special chars, Numbers"
      ) {
        dispatch(
          signUpFailure(
            "Password should have capitalized letters,special characters and numbers."
          )
        );
      } else {
        dispatch(signUpFailure(err.response.data.message));
      }
    }
  };
};

export const signIn = ({ username, password }) => {
  return async (dispatch) => {
    try {
      dispatch(signInStart());
      const signInResponse = await Auth.signIn(username, password);
      dispatch(signInSuccess(signInResponse));
      return signInResponse;
    } catch (err) {
      console.log(err.response);
      dispatch(signInFailure("Incorret Password"));
    }
  };
};

export const isRegistred = (email, password) => {
  return async (dispatch) => {
    const data = { username: email, password };
    dispatch(signInStart());
    try {
      const isRegistred = await axios.post(
        "https://api.rubikhomes.com/user-service/buy/login",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return isRegistred;
    } catch (err) {
      dispatch(signInFailure(err.response.data.message || err.message));
    }
  };
};

export const signOut = () => {
  return async (dispatch) => {
    try {
      dispatch(signOutStart());
      const signOutResponse = await Auth.signOut();
      dispatch(signOutSuccess());
      return signOutResponse;
    } catch (err) {
      dispatch(signOutFailure(err.message));
    }
  };
};

export const checkUserSession = () => {
  return async (dispatch) => {
    try {
      const isAuthed = await Auth.currentSession();
      dispatch(checkUserSessionSuccess(isAuthed));
    } catch (err) {
      if (err === "No current user") dispatch(signOut());
      dispatch(checkUserSessionFailure(err));
    }
  };
};

export const FetchEmail = (email) => {
  return async (dispatch) => {
    axios
      .post("https://api.rubikhomes.com/user-service/user/forgot-password", {
        username: `${email}`,
      })
      .then((res) => {})
      .catch((err) => {
        const fields = err.response.data.message.split(":");
        const msg = fields[1];
        dispatch(changePasswordFailure(msg));
        dispatch(toggleVerificationCodePopup());
        dispatch(toggleForgotPasswordPopup());
      });
  };
};

export const FetchPassword = (username, password, code) => {
  return async (dispatch) => {
    axios
      .post(
        "https://api.rubikhomes.com/user-service/user/confirm-forgot-password",
        {
          username: `${username.trim()}`,
          password: `${password}`,
          code: `${code.trim()}`,
        }
      )
      .then((res) => {
        // dispatch(changePasswordFailure(res.data.message))
        dispatch(switchToSignIn());
      })
      .catch((err) => {
        const substr = err.response.data.message.substring(
          err.response.data.message.indexOf(":") + 1
        );

        if (err.response.data.message === "Invalid Verification code") {
          dispatch(changePasswordFailure("Invalid Verification code or email"));
          dispatch(toggleResetPasswordPopup());
          dispatch(toggleForgotPasswordPopup());
        } else if (substr.includes("Password does not")) {
          dispatch(changePasswordFailure(substr));
        } else {
          const fields = err.response.data.message.split(":");
          const msg = fields[1];
          dispatch(toggleResetPasswordPopup());
          dispatch(changePasswordFailure(msg));
          dispatch(toggleForgotPasswordPopup());
        }
      });
  };
};

export const changeInfo = ({ firstName, lastName, email, phoneNo }) => {
  return async (dispatch) => {
    try {
      dispatch(changeInfoStart());

      const session = await Auth.currentSession();
      const token = session.accessToken.jwtToken;
      const payload = session.idToken.payload;

      const fullName = payload.name.split(" ");

      const userFirstName = firstName || fullName[0];
      const userLastName = lastName || fullName[1];

      const userName = userFirstName + " " + userLastName;
      const userEmail = email || payload.email;
      const userPhoneNo = phoneNo || payload.phone_number;

      let changeInfoResponse = await axios.post(
        "https://api.rubikhomes.com/user-service/user/update",
        {
          email: userEmail,
          name: userName,
          phone_number: userPhoneNo,
        },
        {
          headers: {
            "X-Rubik-Authorization": `Bearer ${token}`,
          },
        }
      );

      dispatch(changeInfoSuccess());
      dispatch(changeUserCredential(firstName));
      return changeInfoResponse;
    } catch (err) {
      console.log(err.message);
      dispatch(changeInfoFailure(err.message));
    }
  };
};

export const changeProfileImg = (img) => {
  return async (dispatch) => {
    const data = new FormData();
    data.append("picture", img);

    const session = await Auth.currentSession();
    const token = session.accessToken.jwtToken;

    axios({
      url: "https://api.rubikhomes.com/user-service/user/image/update",
      headers: {
        "X-Rubik-Authorization": `Bearer ${token}`,
      },
      method: "POST",
      data: data,
    })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const changePassword = (currentPassword, newPassword) => {
  return async (dispatch) => {
    const session = await Auth.currentSession();
    const token = session.accessToken.jwtToken;
    dispatch(changePasswordStart());

    try {
      let response = await axios({
        url: "https://api.rubikhomes.com/user-service/user/password/update",
        headers: {
          "X-Rubik-Authorization": `Bearer ${token}`,
        },
        method: "POST",
        data: {
          current_password: currentPassword,
          new_password: newPassword,
        },
      });
      dispatch(changePasswordSuccess());
      dispatch(confirmPasswordFailure("CHANGE PASSWORD SUCCESS"));
      return response;
    } catch (err) {
      console.log(err.response);
      dispatch(changePasswordFailure());
      dispatch(confirmPasswordFailure("Incorrect password"));
    }
  };
};

export const confirmEmail = (username, code) => {
  return async (dispatch) => {
    try {
      let response = await axios({
        url: "https://api.rubikhomes.com/user-service/user/confirm-signup",
        method: "POST",
        data: {
          username,
          code,
        },
      });
      // console.log(response);
      dispatch(confirmEmailSuccess());
      dispatch(toggleConfirmEmailPopup());
      return response;
    } catch (err) {
      console.log(err.response);
      dispatch(confirmEmailFailure());
      dispatch(toggleConfirmEmailPopup());
    }
  };
};

export const confirmSignUpVerificationCode = (code) => {
  return async (dispatch) => {
    dispatch(signupVerificationCodeStart());
    try {
      let response = await axios({
        url: "https://api.rubikhomes.com/user-service/code/verify",
        method: "POST",
        data: {
          verification_code: code,
        },
      });
      // console.log(response);
      dispatch(signupVerificationCodeSuccess());
      dispatch(switchToSignUp());
      return response;
    } catch (err) {
      console.log(err.response);
      // dispatch(confirmEmailFailure());
      dispatch(signupVerificationCodeFailure(err.response.data.message));
    }
  };
};
