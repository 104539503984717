import { useEffect, useState } from "react";

export const useWindowScrollPosition = () => {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    window.addEventListener(
      "scroll",
      (e) => {
        setScroll(window.pageYOffset);
      },
      { passive: true }
    );

    return () => {
      window.removeEventListener("scroll", null);
    };
  }, []);

  return scroll;
};
