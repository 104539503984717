import {
  FETCH_FEATURE_PROPS_START,
  FETCH_FEATURE_PROPS_SUCCESS,
  FETCH_FEATURE_PROPS_FAILURE,
} from "./feature-properties.types";
import { formatFeatured } from "./feature-properties.utlis";

const initialState = {
  featureProperties: [],
  isFetching: false,
  error: null,
};

const featurePropertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FEATURE_PROPS_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_FEATURE_PROPS_SUCCESS:
      return {
        ...state,
        featureProperties: formatFeatured(action.payload),
        isFetching: false,
      };
    case FETCH_FEATURE_PROPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default featurePropertiesReducer;
