import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { toggleSignInPopup } from "redux/popup/popup.actions";

const Footer = ({ mixpanel, currentUser, toggleSignInPopup }) => {
  const history = useHistory();
  const [state] = useState({
    visible: "show",
  });

  return (
    <footer
      className={`footer has-bg-image ${state.visible}`}
      data-testid="footer"
    >
      <div className="container">
        <div>
          <div className="columns-footer">
            <div className="column-3">
              <h5>Company</h5>
              <ul className="footer-links">
                <li>
                  <Link
                    to="/about"
                    onClick={() =>
                      mixpanel.track("Pressed About link in the footer")
                    }
                  >
                    About
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/about"
                    onClick={() =>
                      mixpanel.track("Pressed Investors link in the footer")
                    }
                  >
                    Investors
                  </Link>
                </li> */}
                <li>
                  <Link
                    onClick={() => {
                      mixpanel.track("Pressed Search link in the footer");
                      if (currentUser)
                        return history.push("/search/page/1?state=Texas");
                      return toggleSignInPopup("/search/page/1?state=Texas");
                    }}
                    to={currentUser ? "/search/page/1?state=Texas" : ""}
                  >
                    Search
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    onClick={() =>
                      mixpanel.track("Pressed Team link in the footer")
                    }
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
            <div className="column-3">
              <h5>Address</h5>
              <address className="footer-address">
                Rubik, Inc.
                <span>41 Madison Ave, 25th Floor,</span>
                <span>New York, NY, 10010</span>
              </address>
            </div>
            <div className="column-3">
              <h5>Contact</h5>
              <address className="footer-address">
                info@rubikhomes.com
                <span> Phone: (972)-236-7359 </span>
              </address>
            </div>
            <div className="column-3">
              <h5>Social Media</h5>
              <ul className="footer-social-media">
                <li>
                  <a
                    href="https://www.facebook.com/rubikhomes"
                    target="_blank"
                    onClick={() =>
                      mixpanel.track("Pressed Facebook icon on footer")
                    }
                    // without
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      className="social-footer-icon"
                      icon={faFacebookF}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/rubikanalytics/"
                    target="_blank"
                    onClick={() =>
                      mixpanel.track("Pressed LinkedIn icon on footer")
                    }
                    // without
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      className="social-footer-icon"
                      icon={faLinkedinIn}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/RubikAnalytics"
                    target="_blank"
                    onClick={() =>
                      mixpanel.track("Pressed Twitter icon on footer")
                    }
                    // without
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      className="social-footer-icon"
                      icon={faTwitter}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyright-description">
            <p>
              Neither the Securities and Exchange Commission nor any federal or
              state securities commission or any other regulatory authority has
              recommended or approved of the investment or the accuracy or
              inaccuracy of any of the information or materials provided by or
              through the website. Direct and indirect purchase of real estate
              property involves significant risks, including, but not limited to
              risk related to sale of land, market and industry risks and risks
              specific to a given property. Investments are not bank deposits,
              are not insured by the FDIC or by any other Federal Government
              Agency, are not guaranteed by Rubik, Inc., and may lose value.{" "}
              <br />
              <br />
              Rubik, Inc. does not make investment recommendations, and any
              information found herein should not be construed as such.
              Information found on this website is not an offer to sell or the
              solicitation of an offer to buy any security, which can only be
              made through official offering documents that contain important
              information about risks, fees and expenses. Any investment
              information contained herein has been secured from sources Rubik,
              Inc. believes are reliable, but we make no representations or
              warranties as to the accuracy of such information and accept no
              liability therefor. We recommend that you consult with a financial
              advisor, attorney, accountant, and any other professional that can
              help you to understand and assess the risks associated with any
              investment opportunity.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSignInPopup: (redirectPath) =>
    dispatch(toggleSignInPopup(redirectPath)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
