export const FETCH_SAVED_DEALS_START = "FETCH_SAVED_DEALS_START";
export const FETCH_SAVED_DEALS_SUCCESS = "FETCH_SAVED_DEALS_SUCCESS";
export const FETCH_SAVED_DEALS_FAILURE = "FETCH_SAVED_DEALS_FAILURE";

export const FAVORITE_DEAL_SUCCESS = "FAVORITE_DEAL_SUCCESS";
export const FAVORITE_DEAL_FAILURE = "FAVORITE_DEAL_FAILURE";

export const UNFAVORITE_DEAL_SUCCESS_FROM_LIST =
  "UNFAVORITE_DEAL_SUCCESS_FROM_LIST";
export const UNFAVORITE_DEAL_SUCCESS = "UNFAVORITE_DEAL_SUCCESS";
export const UNFAVORITE_DEAL_FAILURE = "UNFAVORITE_DEAL_FAILURE";
