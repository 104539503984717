export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const SIGN_IN_START = "SIGN_IN_START";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";

export const SIGN_OUT_START = "SIGN_OUT_START";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const CHECK_USER_SESSION_SUCCESS = "CHECK_USER_SESSION_SUCCESS";
export const CHECK_USER_SESSION_FAILURE = "CHECK_USER_SESSION_FAILURE";

export const CONFIRM_PASSWORD_FAILURE = "CONFIRM_PASSWORD_FAILURE";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const CONFIRM_EMAIL_SUCCESS = "CONFIRM_EMAIL_SUCCESS";
export const CONFIRM_EMAIL_FAILURE = "CONFIRM_EMAIL_FAILURE";

export const INSERT_USER_START = "INSERT_USER_START";
export const INSERT_USER_SUCCESS = "INSERT_USER_SUCCESS";
export const INSERT_USER_FAILURE = "INSERT_USER_FAILURE";

export const INSERT_FILTER_START = "INSERT_FILTER_START";
export const INSERT_FILTER_SUCCESS = "INSERT_FILTER_SUCCESS";
export const INSERT_FILTER_FAILURE = "INSERT_FILTER_FAILURE";

export const INSERT_USER_FILTER_START = "INSERT_FILTER_START";
export const INSERT_USER_FILTER_SUCCESS = "INSERT_FILTER_SUCCESS";
export const INSERT_USER_FILTER_FAILURE = "INSERT_FILTER_FAILURE";

export const CHANGE_INFO_START = "CHANGE_INFO_START";
export const CHANGE_INFO_SUCCESS = "CHANGE_INFO_SUCCESS";
export const CHANGE_INFO_FAILURE = "CHANGE_INFO_FAILURE";

export const SIGN_UP_VERIFICATION_CODE_START =
  "SIGN_UP_VERIFICATION_CODE_START";
export const SIGN_UP_VERIFICATION_CODE_SUCCESS =
  "SIGN_UP_VERIFICATION_CODE_SUCCESS";
export const SIGN_UP_VERIFICATION_CODE_FAILURE =
  "SIGN_UP_VERIFICATION_CODE_FAILURE";

export const CHANGE_USER_CREDENTIAL = "CHANGE_USER_CREDENTIAL";
