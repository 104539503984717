import React from "react";

import "./no-deals.scss";

const NoDeals = ({ children, h3, width, removeRefresh }) => (
  <div className="no-deals">
    <div className="card" style={{ width: width ? width : "" }}>
      {children}
      <div>
        <h3>{h3}</h3>
        {removeRefresh ? null : (
          <h5>
            Please <a href="https://www.rubikhomes.com">click here</a> to
            refresh.
          </h5>
        )}
      </div>
    </div>
  </div>
);

export default NoDeals;