import React, { useEffect, useState, useRef, useReducer } from "react";
import { connect } from "react-redux";
import Select from "react-select";

import Input from "components/shared-components/input-field/input-field";
import CheckEmailPopup from "./check-email-popup";
import Alert from "components/shared-components/alert/alert";
import ListHomeDetails from "components/shared-components/list-home-details/list-home-details";

import { ReactComponent as CloseX } from "./close.svg";
import { ReactComponent as UploadIcon } from "./upload.svg";

import { formatNumber } from "utilis/formatNumber";

import { toggleSubmitForm } from "redux/popup/popup.actions";

import {
  submitForm,
  clearFormError,
  resetFormStatus,
} from "redux/submit-offer/submit-offer.actions";

import "./submit-offer-popup.scss";

const SubmitOfferPopup = ({
  options,
  submitForm,
  submitFormError,
  clearFormError,
  submitedSuccesfuly,
  setModal,
  submitingForm,
  resetFormStatus,
  toggleSubmitForm,
  property: {
    list_price,
    earnest_money,
    images,
    address,
    bedrooms,
    baths,
    sqft,
    closing_date,
    closing_cost,
    company_name,
  },
  mixpanel,
}) => {
  const InspectionPeriodOptions = [
    { value: "1 Day", label: "1 Day", name: "inspection_period" },
    { value: "2 Days", label: "2 Days", name: "inspection_period" },
    { value: "3 Days", label: "3 Days", name: "inspection_period" },
    { value: "4 Days", label: "4 Days", name: "inspection_period" },
    { value: "5 Days", label: "5 Days", name: "inspection_period" },
  ];

  const FinancingOptions = [
    { value: "Cash", label: "Cash", name: "financing_options" },
    { value: "Hard Money", label: "Hard Money", name: "financing_options" },
    {
      value: "Traditional Financing",
      label: "Traditional Financing",
      name: "financing_options",
    },
  ];

  const ClosingCostOptions = [
    { value: "Paid by Buyer", label: "Paid by Buyer", name: "closing_cost" },
    { value: "Paid by Seller", label: "Paid by Seller", name: "closing_cost" },
    {
      value: "Split by Buyer & Seller",
      label: "Split by Buyer & Seller",
      name: "closing_cost",
    },
  ];

  const UploadInput = useRef(null);

  const [buttonClick, setButtonClick] = useState(false);
  const [currentState, setCurrentState] = useState("");
  const [checkEmailPopup, setCheckEmailPopup] = useState(false);

  const [submitData, setSubmitData] = useState({
    purchaser_name: "",
    trust_fund: "",
    email: "",
    phone_number: "",
    offer_price: "",
    inspection_period: "",
    financing_options: "",
    proof_of_funds: [],
  });

  const [submitOpenState, setSubmitOpenState] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      yourInfo: { value: false, isSaved: false },
      offer: { value: false, isSaved: false },
      foundingClosing: { value: false, isSaved: false },
      reviewSubmit: { value: false, isSaved: false },
    }
  );

  const { yourInfo, offer, foundingClosing, reviewSubmit } = submitOpenState;

  useEffect(() => {
    clearFormError();
    return () => {
      clearFormError();
    };
  }, []);

  useEffect(() => {
    if (currentState) {
      setSubmitOpenState({
        [currentState]: {
          value: !submitOpenState[currentState]["value"],
          isSaved: submitOpenState[currentState]["value"] ? true : false,
        },
      });
    }
  }, [buttonClick]);

  useEffect(() => {
    if (currentState && currentState !== "reviewSubmit") {
      if (
        !submitOpenState[currentState]["value"] &&
        submitOpenState[currentState]["isSaved"]
      ) {
        const index = Object.keys(submitOpenState).indexOf(currentState);
        const key = Object.keys(submitOpenState)[index + 1];
        if (!submitOpenState[key].value) {
          handleToggle(key);
        }
      }
    }
  }, [submitOpenState]);

  const uploadProof = (e) => {
    e.persist();
    setSubmitData({ ...submitData, proof_of_funds: [e.target.files[0]] });
  };

  const onFilterChange = (event) => {
    const { name, value } = event;
    setSubmitData({ ...submitData, [name]: value });
  };

  const onInputChange = (event) => {
    const { value, name } = event.target;
    setSubmitData({ ...submitData, [name]: value });
  };

  const handleToggle = (state, className) => {
    let index;
    let isAllowed = true;

    Object.keys(submitOpenState).forEach((key, i) => {
      if (key === state) index = i;
    });

    Object.keys(submitOpenState).forEach((key, i) => {
      if (i < index) {
        if (submitOpenState[key].isSaved === false) {
          isAllowed = false;
        }
      }
    });

    if (!submitOpenState[state].value && isAllowed) styleDots(index);

    const reviewBtn = state === "reviewSubmit" && submitOpenState[state].value;
    const isRequired =
      submitOpenState[state].value && className
        ? checkRequired(className)
        : false;

    if (isAllowed && !reviewBtn && !isRequired) {
      setCurrentState(state);
      setButtonClick(!buttonClick);
    }

    if (reviewBtn) handleSubmit();
  };

  const styleDots = (index) => {
    const trackers = document.querySelectorAll(".submit-offer-tracker div");
    const numbers = document.querySelectorAll(".submit-offer-process .number");

    trackers.forEach((tracker, i) => {
      tracker.classList.remove("active");
      for (let a = 0; a <= index + 1; a++) {
        trackers[a].classList.add("active");
      }
    });

    numbers.forEach((number, i) => {
      number.classList.remove("active");
      for (let a = 0; a <= index + 1; a++) {
        numbers[a].classList.add("active");
      }
    });

    const trackerLine = document.querySelector(
      ".submit-offer-tracker .tracker-line span"
    );

    switch (index) {
      case 0:
        trackerLine.style.width = "26%";
        break;
      case 1:
        trackerLine.style.width = "50%";
        break;
      case 2:
        trackerLine.style.width = "67%";
        break;
      case 3:
        trackerLine.style.width = "100%";
        break;
      default:
        trackerLine.style.width = "0%";
    }
  };

  const checkRequired = (className) => {
    let index = 0;
    const inputs = document.querySelectorAll(`.${className} input`);
    inputs.forEach((input, i) => {
      if (input.hasAttribute("required") && input.value === "") {
        index++;
        input.parentElement.classList.add("input-error");
      }
    });

    if (className === "submit-offer-offer") {
      if (!submitData.inspection_period) {
        const inputSelect = document.querySelector(".inspection_period");
        inputSelect.classList.add("input-error");
        index++;
      }
    }

    if (className === "submit-offer-founding-and-closing") {
      if (!submitData.financing_options) {
        const inputSelect = document.querySelector(".financing_options");
        inputSelect.classList.add("input-error");
        index++;
      }
    }

    return !!index;
  };

  const handleSubmit = async (e) => {
    const res = await submitForm({ ...submitData });
    if (res) setCheckEmailPopup(true);
  };

  const handleClose = (state) => {
    setSubmitOpenState({
      [state]: {
        value: false,
        isSaved: false,
      },
    });
  };

  return (
    <div className="popup-ctn">
      {!checkEmailPopup && (
        <>
          <div className="submit-offer-tracker">
            <span className="tracker-line">
              <span />
            </span>
            <div className="active left-circle">
              Property <br /> Information
            </div>
            <div className="left-circle">
              Buyer <br /> Information
            </div>
            <div className="center-circle">Offer</div>
            <div className="right-circle">
              Funding
              <br />
              &amp; Closing
            </div>
            <div className="right-circle">
              Review <br /> and Submit
            </div>
          </div>
          <div className="submit-offer">
            <div>
              <div className="submit-offer-process submit-offer-property-information">
                <div className="submit-offer-process-title">
                  <div className="number active">1.</div>
                  <h3>Property Information</h3>
                </div>
                <div className="submit-offer-process-body">
                  <div className="property-details">
                    <div className="property-img">
                      <img src={images?.[0] ? images[0] : ""} alt={address} />
                    </div>
                    <div className="property-info">
                      <p className="address">{address}</p>
                      <ListHomeDetails
                        beds={bedrooms}
                        baths={baths}
                        sqft={sqft}
                        className="submit-property"
                      />
                      <h4 className="price">
                        First Price: ${formatNumber(list_price)}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`submit-offer-process submit-offer-buyer-info ${
                  yourInfo.value ? "" : "closed"
                }`}
              >
                <div className="submit-offer-process-title">
                  <div className="number">2.</div>
                  <h3>Buyer Information</h3>
                  <div
                    className="close-btn"
                    onClick={() => handleClose("yourInfo")}
                  >
                    <CloseX />
                  </div>
                </div>
                <div className="submit-offer-process-body body-inputs-ctn">
                  <div className="body-inputs">
                    <Input
                      type="text"
                      label="Name of purchaser*"
                      name="purchaser_name"
                      required
                      onChange={onInputChange}
                    />
                    <Input
                      type="text"
                      label="LLC, Trust or a Fund"
                      name="trust_fund"
                      onChange={onInputChange}
                    />
                    <Input
                      type="email"
                      label="Email*"
                      name="email"
                      required
                      onChange={onInputChange}
                    />
                    <Input
                      type="text"
                      label="Phone Number*"
                      name="phone_number"
                      required
                      onChange={onInputChange}
                    />
                  </div>
                  <button
                    className="open-btn"
                    onClick={() =>
                      handleToggle("yourInfo", "submit-offer-buyer-info")
                    }
                  >
                    {yourInfo.value
                      ? "Save"
                      : yourInfo.isSaved
                      ? "Edit"
                      : "Start"}
                  </button>
                </div>
              </div>
              <div
                className={`submit-offer-process submit-offer-offer ${
                  offer.value ? "" : "closed"
                }`}
              >
                <div className="submit-offer-process-title">
                  <div className="number">3.</div>
                  <h3>Offer</h3>
                  <div
                    className="close-btn"
                    onClick={() => handleClose("offer")}
                  >
                    <CloseX />
                  </div>
                </div>
                <div className="submit-offer-process-body body-inputs-ctn">
                  <div className="body-inputs">
                    <Input
                      type="text"
                      label="Offer Price*"
                      name="offer_price"
                      required
                      onChange={onInputChange}
                    />
                    <div className="static-info">
                      <span>Ernest Money Deposit</span>
                      <span>${earnest_money}</span>
                    </div>
                    <div className="select-ctn inspection_period">
                      <Select
                        options={InspectionPeriodOptions}
                        placeholder="Inspection Period*"
                        onChange={onFilterChange}
                        onFocus={(e) => {
                          e.target
                            .closest(".inspection_period")
                            .classList.remove("input-error");
                        }}
                        isSearchable={false}
                      />
                    </div>
                    {closing_date && (
                      <div className="static-info">
                        <span>Closing Date</span>
                        <span>{closing_date}</span>
                      </div>
                    )}
                  </div>
                  <button
                    className="open-btn"
                    onClick={() => handleToggle("offer", "submit-offer-offer")}
                  >
                    {offer.value ? "Save" : offer.isSaved ? "Edit" : "Start"}
                  </button>
                </div>
              </div>
              <div
                className={`submit-offer-process submit-offer-founding-and-closing ${
                  foundingClosing.value ? "" : "closed"
                }`}
              >
                <div className="submit-offer-process-title">
                  <div className="number">4.</div>
                  <h3>Funding &amp; Closing </h3>
                  <div
                    className="close-btn"
                    onClick={() => handleClose("foundingClosing")}
                  >
                    <CloseX />
                  </div>
                </div>
                <div className="submit-offer-process-body body-inputs-ctn">
                  <div className="body-inputs">
                    <div className="select-ctn financing_options">
                      <Select
                        options={FinancingOptions}
                        placeholder="Financing*"
                        onChange={onFilterChange}
                        onFocus={(e) => {
                          e.target
                            .closest(".financing_options")
                            .classList.remove("input-error");
                        }}
                        isSearchable={false}
                      />
                    </div>
                    <div
                      className="proof-of-founds static-info"
                      onClick={() => {
                        UploadInput.current.click();
                      }}
                    >
                      {!submitData.proof_of_funds.length ? (
                        <>
                          <span>Proof of Founds</span>
                          <span>
                            <UploadIcon />
                          </span>
                        </>
                      ) : (
                        <>
                          <span>{submitData.proof_of_funds[0]?.name}</span>
                        </>
                      )}

                      <input
                        type="file"
                        accept="application/pdf"
                        ref={UploadInput}
                        onChange={uploadProof}
                      />
                    </div>
                    {company_name && (
                      <div className="static-info">
                        <span>Title Company</span>
                        <span>{company_name}</span>
                      </div>
                    )}
                    {/* <Select
                      options={ClosingCostOptions}
                      placeholder="Closing Costs"
                      // onChange={onFilterChange}
                    /> */}
                    {closing_cost && (
                      <div className="static-info">
                        <span>Closing Costs</span>
                        <span>{closing_cost}</span>
                      </div>
                    )}
                  </div>
                  <button
                    className="open-btn"
                    onClick={() =>
                      handleToggle(
                        "foundingClosing",
                        "submit-offer-founding-and-closing"
                      )
                    }
                  >
                    {foundingClosing.value
                      ? "Save"
                      : foundingClosing.isSaved
                      ? "Edit"
                      : "Start"}
                  </button>
                </div>
              </div>
              <div
                className={`submit-offer-process submit-offer-review-and-submit ${
                  reviewSubmit.value ? "" : "closed"
                }`}
              >
                <div className="submit-offer-process-title">
                  <div className="number">5.</div>
                  <h3>Review &amp; Submit</h3>
                  <div
                    className="close-btn"
                    onClick={() => handleClose("reviewSubmit")}
                  >
                    <CloseX />
                  </div>
                </div>
                <div className="submit-offer-process-body review-body">
                  <div className="property-details">
                    <div className="property-img">
                      <img src={images?.[0] ? images[0] : ""} alt={address} />
                    </div>
                    <div className="property-info">
                      <p className="address">{address}</p>
                      <div className="review-info">
                        <div>{submitData.purchaser_name}</div>
                        <div>{submitData.trust_fund}</div>
                        <div>{submitData.email}</div>
                        <div className="offer-price">
                          {submitData.offer_price &&
                            `Offer Price : $${submitData.offer_price}`}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!submitingForm ? (
                    <button
                      className="open-btn"
                      onClick={() => handleToggle("reviewSubmit")}
                    >
                      {reviewSubmit.value ? "Continue to PandaDoc" : "Start"}
                    </button>
                  ) : (
                    <button className="open-btn">Submiting...</button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {submitFormError && <Alert message={submitFormError} />}
        </>
      )}
      {checkEmailPopup && <CheckEmailPopup onClick={toggleSubmitForm} />}
    </div>
  );
};

const mapStateToProps = ({
  submitOffer: { options, submitFormError, submitedSuccesfuly, submitingForm },
  listingDetail: { property },
}) => ({
  options,
  submitFormError,
  submitingForm,
  submitedSuccesfuly,
  property,
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: (formInputs) => dispatch(submitForm(formInputs)),
  clearFormError: () => dispatch(clearFormError()),
  resetFormStatus: () => dispatch(resetFormStatus()),
  toggleSubmitForm: () => dispatch(toggleSubmitForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitOfferPopup);
