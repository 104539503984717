import {
  TOGGLE_SIGN_IN_POPUP,
  TOGGLE_SIGN_UP_POPUP,
  TOGGLE_FORGOT_PASSWORD_POPUP,
  TOGGLE_VERIFICATION_CODE_POPUP,
  TOGGLE_RESET_PASSWORD_POPUP,
  TOGGLE_MOBILE_MENU,
  SWITCH_TO_SIGN_IN,
  SWITCH_TO_SIGN_UP,
  CLOSE_ALL_POPUPS,
  TOGGLE_QUESTIONS_POPUP,
  SWITCH_TO_QUESTIONS,
  SET_EMAIL,
  SET_VERIFICATION_CODE,
  TOGGLE_SCHEDULECALL_POPUP,
  TOGGLE_SUBMIT_FORM,
  TOGGLE_SIGN_UP_VERIFICATION_CODE,
  TOGGLE_CONFIRM_EMAIL_POPUP,
  TOOGLE_CHECK_EMAIL_POPUP,
} from "./popup.types";

const initialState = {
  loginPopupShown: false,
  registerPopupShown: false,
  questionsPopupshown: false,
  forgetPasswordPopupShown: false,
  verificationCodePopupShown: false,
  resetPasswordPopupShown: false,
  mobileMenuActive: false,
  redirectPath: "",
  code: "",
  email: "",
  scheduleCallPopupShown: false,
  submitFormPopupShown: false,
  signupVerificationCodePopupShown: false,
  confirmEmailPopupShown: false,
  checkEmailPopupShown: false,
};

const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIGN_IN_POPUP:
      return {
        ...state,
        loginPopupShown: !state.loginPopupShown,
        redirectPath: action.payload,
      };
    case TOGGLE_SIGN_UP_POPUP:
      return {
        ...state,
        registerPopupShown: !state.registerPopupShown,
      };
    case TOGGLE_QUESTIONS_POPUP:
      return {
        ...state,
        questionsPopupshown: !state.questionsPopupshown,
      };
    case TOGGLE_FORGOT_PASSWORD_POPUP:
      return {
        ...state,
        forgetPasswordPopupShown: !state.forgetPasswordPopupShown,
        loginPopupShown: false,
        registerPopupShown: false,
      };
    case TOGGLE_VERIFICATION_CODE_POPUP:
      return {
        ...state,
        verificationCodePopupShown: !state.verificationCodePopupShown,
        loginPopupShown: false,
        registerPopupShown: false,
      };
    case TOGGLE_RESET_PASSWORD_POPUP:
      return {
        ...state,
        resetPasswordPopupShown: !state.resetPasswordPopupShown,
        loginPopupShown: false,
        registerPopupShown: false,
      };
    case SWITCH_TO_SIGN_IN:
      return {
        ...state,
        loginPopupShown: true,
        resetPasswordPopupShown: false,
        registerPopupShown: false,
        scheduleCallPopupShown: false,
        confirmEmailPopupShown: false,
      };
    case SWITCH_TO_QUESTIONS:
      return {
        ...state,
        registerPopupShown: false,
        questionsPopupshown: true,
      };
    case SWITCH_TO_SIGN_UP:
      return {
        ...state,
        loginPopupShown: false,
        registerPopupShown: true,
        signupVerificationCodePopupShown: false,
      };
    case TOGGLE_MOBILE_MENU:
      return {
        ...state,
        mobileMenuActive: !state.mobileMenuActive,
      };
    case CLOSE_ALL_POPUPS:
      return {
        ...state,
        loginPopupShown: false,
        registerPopupShown: false,
        questionsPopupshown: false,
        forgetPasswordPopupShown: false,
        verificationCodePopupShown: false,
        resetPasswordPopupShown: false,
        scheduleCallPopupShown: false,
        submitFormPopupShown: false,
        signupVerificationCodePopupShown: false,
        confirmEmailPopupShown: false,
        checkEmailPopupShown: false,
        email: "",
        code: "",
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_VERIFICATION_CODE:
      return {
        ...state,
        code: action.payload,
      };
    case TOGGLE_SCHEDULECALL_POPUP:
      return {
        ...state,
        scheduleCallPopupShown: !state.scheduleCallPopupShown,
      };
    case TOGGLE_SUBMIT_FORM:
      return {
        ...state,
        submitFormPopupShown: !state.submitFormPopupShown,
      };
    case TOGGLE_SIGN_UP_VERIFICATION_CODE:
      return {
        ...state,
        signupVerificationCodePopupShown:
          !state.signupVerificationCodePopupShown,
      };
    case TOGGLE_CONFIRM_EMAIL_POPUP:
      return {
        ...state,
        confirmEmailPopupShown: !state.confirmEmailPopupShown,
      };
    case TOOGLE_CHECK_EMAIL_POPUP:
      return {
        ...state,
        registerPopupShown: false,
        checkEmailPopupShown: !state.checkEmailPopupShown,
      };
    default:
      return state;
  }
};

export default popupReducer;
