export const formatQuestionOptions = (questions) => {
  return questions.map(({ id, options, question }, index) => {
    return {
      id,
      options: options.map(({ id, option }) => ({
        optionId: id,
        value: option,
        label: option,
        name: `question${index + 1}`,
      })),
      question,
    };
  });
};
