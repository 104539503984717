import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Observer from "react-intersection-observer";
import Plx from "react-plx";

import { toggleScheduleCallPopup } from "redux/popup/popup.actions";

import { MixpanelConsumer } from "react-mixpanel";

import "../off-market/call-to-action.scss";

const parallax = [
  {
    start: 0,
    duration: 4000,
    properties: [
      {
        startValue: 0,
        endValue: -0,
        property: "translateY",
      },
    ],
  },
];

const NextDeal = ({ toggleScheduleCallPopup }) => {
  const [animate, setAnimate] = useState(false);
  const history = useHistory();
  return (
    <Observer
      onChange={(inView) => (inView ? setAnimate(true) : setAnimate(false))}
      className={animate ? "" : ""}
    >
      <div className="call-to-action has-background-color">
        <div className="container">
          <div className="call-to-action__inner reverse mobile-top-title">
            <div className="column-text">
              <div className="text-content">
                <h3>
                  Discover your <br></br>
                  next deal
                </h3>
                <p>
                  Please schedule a brief call with a Rubik representative to
                  tell us more about your investment criteria. After the
                  onboarding call, you will be granted access to our properties
                  and will be able to start closing deals right away.
                </p>
                <MixpanelConsumer>
                  {(mixpanel) => (
                    <button
                      onClick={() => {
                        mixpanel.track(
                          "Pressed Get Started button on landing page"
                        );
                        toggleScheduleCallPopup();
                      }}
                      className="button button--light"
                    >
                      Get Started
                    </button>
                  )}
                </MixpanelConsumer>
              </div>
            </div>
            <div className="column-images">
              <div className="next-deal">
                <img src={require("./next-deal.png")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Observer>
  );
};

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  toggleScheduleCallPopup: (redirectPath) =>
    dispatch(toggleScheduleCallPopup(redirectPath)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NextDeal);
