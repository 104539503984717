import {
  FETCH_QUESTIONS_START,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  POST_QUESTION_START,
  POST_QUESTION_SUCCESS,
  POST_QUESTION_FAILURE,
  CHECK_IF_ANSWERED_QUESTIONS,
} from "./questions.types";

import { formatQuestionOptions } from "./questions.utils";

const initialState = {
  questions: [],
  isFetching: false,
  error: null,
  isAnsweringQuestions: false,
  answeringError: null,
  answeredSuccess: false,
  questionsSession: null,
};

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUESTIONS_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: formatQuestionOptions(action.payload),
        isFetching: false,
      };
    case FETCH_QUESTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case POST_QUESTION_START:
      return {
        ...state,
        isAnsweringQuestions: true,
      };
    case POST_QUESTION_SUCCESS:
      return {
        ...state,
        answeredSuccess: true,
        isAnsweringQuestions: false,
      };
    case POST_QUESTION_FAILURE:
      return {
        ...state,
        isAnsweringQuestions: false,
        answeringError: action.payload,
      };
    case CHECK_IF_ANSWERED_QUESTIONS:
      return {
        ...state,
        questionsSession: action.payload,
      };
    default:
      return state;
  }
};

export default questionsReducer;
