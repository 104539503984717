import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ReactComponent as Logo } from "../logo.svg";

import Alert from "components/shared-components/alert/alert";

import {
  confirmPasswordFailure,
  clearError,
  FetchPassword,
} from "redux/auth/auth.actions";

const ResetPassword = ({
  email,
  code,
  errorMessage,
  FetchPassword,
  confirmPasswordFailure,
  clearError,
}) => {
  const changePassword = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return confirmPasswordFailure(
        "Your password doesn't match, try it again!"
      );
    } else if (password.length < 8) {
      return confirmPasswordFailure(
        "Your password must be bigger than 8 characters!"
      );
    } else {
      FetchPassword(email, password, code);
    }
  };

  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });
  const [invisiblePassword, setInvisiblePassword] = useState(true);

  const { password, confirmPassword } = passwords;

  const changeInput = (e) => {
    const { value, name } = e.target;
    setPasswords({ ...passwords, [name]: value });
    if (errorMessage) clearError();
  };

  const noPaste = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <div className=" popup page page-reset-password">
      <div className="popup-form login-form is-page">
        <div className="logo">
          <Logo />
        </div>
        <div className="form-holder">
          <form onSubmit={changePassword}>
            <div className="form-input">
              <input
                type={invisiblePassword ? "password" : "text"}
                placeholder="Add New Password"
                name="password"
                // id="new-password"
                value={password}
                onChange={changeInput}
              />
              <span
                className={`show-password ${
                  !invisiblePassword ? "is-input-plain" : ""
                }`}
                onClick={() => setInvisiblePassword(!invisiblePassword)}
              >
                <svg width="16" height="10.909" viewBox="0 0 16 10.909">
                  <path
                    d="M8,10.909a8.533,8.533,0,0,1-4.892-1.53A8.608,8.608,0,0,1,0,5.455a8.6,8.6,0,0,1,16,0,8.607,8.607,0,0,1-3.108,3.924A8.533,8.533,0,0,1,8,10.909ZM8,1.818a3.636,3.636,0,1,0,3.636,3.637A3.641,3.641,0,0,0,8,1.818ZM8,7.637a2.182,2.182,0,1,1,2.182-2.182A2.184,2.184,0,0,1,8,7.637Z"
                    transform="translate(0 0)"
                    fill="#d0c9d6"
                  />
                </svg>
              </span>
            </div>
            <div className="form-input">
              <input
                type="password"
                placeholder="Retype Password"
                name="confirmPassword"
                // id="password-confirm"
                value={confirmPassword}
                onChange={changeInput}
                onPaste={noPaste}
              />
            </div>
            <div className="form-input">
              <button className="button button--default">
                Change Password
              </button>
            </div>
          </form>
        </div>
        <p className="confirm-acc-alert">
          * Your password must contain minimum 8 characters, at least one
          uppercase and lowercase, at least one number and one special
          character.
        </p>
      </div>

      {errorMessage && <Alert message={errorMessage} />}
    </div>
  );
};
const mapStateToProps = ({
  popups: { resetPasswordPopupShown, email, code },
  auth: { loading, errorMessage },
}) => ({
  isPopupVisible: resetPasswordPopupShown,
  code,
  errorMessage,
  email,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  FetchPassword: (username, password, code) =>
    dispatch(FetchPassword(username, password, code)),
  confirmPasswordFailure: (message) =>
    dispatch(confirmPasswordFailure(message)),
  clearError: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
