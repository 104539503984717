import {
  INSERT_USER_NEWSLETTER_START,
  INSERT_USER_NEWSLETTER_SUCCESS,
  INSERT_USER_NEWSLETTER_FAILURE,
  INSERT_FILTER_NEWSLETTER_START,
  INSERT_FILTER_NEWSLETTER_SUCCESS,
  INSERT_FILTER_NEWSLETTER_FAILURE,
  CLEAR_ERROR,
  // INSERT_USER_FILTER_NEWSLETTER_START,
  // INSERT_USER_FILTER_NEWSLETTER_SUCCESS,
  // INSERT_USER_FILTER_NEWSLETTER_FAILURE,
} from "./newsletter.types";

const initialState = {
  insertedUser: null,
  insertingUser: false,
  insertedFilter: false,
  insertingFilter: false,
  error: "",
};

const newsletterReducer = (state = initialState, action) => {
  switch (action.type) {
    case INSERT_USER_NEWSLETTER_START:
      return {
        ...state,
        insertingUser: true,
      };

    case INSERT_USER_NEWSLETTER_SUCCESS:
      return {
        ...state,
        insertingUser: false,
        insertedUser: true,
      };

    case INSERT_USER_NEWSLETTER_FAILURE:
      return {
        ...state,
        insertingUser: false,
        insertedUser: false,
        error: action.payload,
      };
    case INSERT_FILTER_NEWSLETTER_START:
      return {
        ...state,
        insertingFilter: true,
      };

    case INSERT_FILTER_NEWSLETTER_SUCCESS:
      return {
        ...state,
        insertingFilter: false,
        insertedFilter: true,
      };

    case INSERT_FILTER_NEWSLETTER_FAILURE:
      return {
        ...state,
        insertingFilter: false,
        insertedFilter: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: "",
      };
    default:
      return state;
  }
};

export default newsletterReducer;
