import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import popupReducer from "./popup/popup.reducer";
import authReducer from "./auth/auth.reducer";
import featurePropertiesReducer from "./feature-properties/feature-properties.reducer";
import searchPropertyListReducer from "./search-property-list/search-property.reducer";
import listingDetailReducer from "./listing-details/listing-detail.reducer";
import mapReducer from "./map/map.reducer";
import questionsReducer from "./questions/questions.reducer";
import savedDealsReducer from "./saved-deals/saved-deals.reducer";
import submitOfferReducer from "./submit-offer/submit-offer.reducer";
import newsletterReducer from "./newsletter/newsletter.reducer";
import offersAndTransaction from "./offers-and-transactions/offers-and-transactions.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  popups: popupReducer,
  auth: authReducer,
  propertiesList: searchPropertyListReducer,
  featureProperties: featurePropertiesReducer,
  listingDetail: listingDetailReducer,
  map: mapReducer,
  questions: questionsReducer,
  savedDeals: savedDealsReducer,
  submitOffer: submitOfferReducer,
  newsletter: newsletterReducer,
  offers: offersAndTransaction,
});

export default persistReducer(persistConfig, rootReducer);
