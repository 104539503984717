import React, { useEffect } from "react";
import { connect } from "react-redux";

import { InlineWidget } from "react-calendly";
import { switchToSignIn } from "redux/popup/popup.actions";

import "./schedule-call.scss";

const ScheduleCall = ({ switchToSignIn, currentUser }) => {
  return (
    <div className="schedule-call-ctn">
      <InlineWidget
        url="https://calendly.com/rubik/investors"
        id="myFrame"
        styles={{
          width: "100%",
          height: "90%",
          maxHeight: "700px",
        }}
      />
      {!currentUser && (
        <p>
          Already have an account? <span onClick={switchToSignIn}>Log in</span>
        </p>
      )}
    </div>
  );
};

const mapStateToProps = ({ auth: { currentUser } }) => ({
  currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  switchToSignIn: () => dispatch(switchToSignIn()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCall);
