import { useEffect, useState } from "react";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export const useWindowDimensions = () => {
	const [windowDim, setWindowDims] = useState(getWindowDimensions());

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const handleResize = () => {
		setWindowDims(getWindowDimensions());
	};

	return windowDim;
};
