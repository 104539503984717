import {
  FETCH_OFFERS_START,
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFERS_FAILURE,
} from "./offers-and-transactions.types";

import { formatOffers } from "./offers-and-transactions.util";

import {
  FAVORITE_DEAL_SUCCESS,
  UNFAVORITE_DEAL_SUCCESS_FROM_LIST,
} from "../saved-deals/saved-deals.types";

const initialState = {
  offers: [],
  isFetching: false,
  error: null,
};

const OffersAndTransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OFFERS_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_OFFERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        offers: formatOffers(action.payload),
      };
    case FETCH_OFFERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FAVORITE_DEAL_SUCCESS:
      return {
        ...state,
        offers: state.offers.map((item) => {
          if (item.id === action.payload) {
            return Object.assign({}, item, { favorite: true });
          }
          return item;
        }),
      };
    case UNFAVORITE_DEAL_SUCCESS_FROM_LIST:
      return {
        ...state,
        offers: state.offers.map((item) => {
          if (item.id === action.payload) {
            return Object.assign({}, item, { favorite: false });
          }
          return item;
        }),
      };
    default:
      return state;
  }
};

export default OffersAndTransactionReducer;
