export const FETCH_PROPERTIES_LIST_START = "FETCH_PROPERTIES_LIST_START";
export const FETCH_PROPERTIES_LIST_SUCCESS = "FETCH_PROPERTIES_LIST_SUCCESS";
export const FETCH_PROPERTIES_LIST_FAILURE = "FETCH_PROPERTIES_LIST_FAILURE";

export const FETCH_SEARCH_AUTOCOMPLETE_START =
  "FETCH_SEARCH_AUTOCOMPLETE_START";
export const FETCH_SEARCH_AUTOCOMPLETE_SUCCESS =
  "FETCH_SEARCH_AUTOCOMPLETE_SUCCESS";
export const FETCH_SEARCH_AUTOCOMPLETE_FAILURE =
  "FETCH_SEARCH_AUTOCOMPLETE_FAILURE";

export const TOGGLE_MORE_FILTERS = "TOGGLE_MORE_FILTERS";

export const HANDLE_LIST_VIEW = "HANDLE_LIST_VIEW";
export const HANDLE_THUMBLIST_VIEW = "HANDLE_THUMBLIST_VIEW";

export const FOLD_LIST_VIEW = "FOLD_LIST_VIEW";
export const TOGGLE_FULL_MAP = "TOGGLE_FULL_MAP";

export const SET_FILTER_VALUES = "SET_FILTER_VALUES";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
