import React, { useState } from "react";

import NoDeals from "components/deals/no-deals/no-deals";
import { ReactComponent as ErrorLogo } from "./error-icon.svg";

import "./error-boundry.scss";

const ErrorBoundry = (props) => {
  const [hasError, setHasError] = useState(false);

  if (hasError) {
    return (
      <div className="error-boundry">
        <NoDeals width="20vw" h3="Oops, something went wrong here!">
          <ErrorLogo />
        </NoDeals>
      </div>
    );
  }

  return props.children;
};

export default ErrorBoundry;
