import {
  INSERT_USER_NEWSLETTER_START,
  INSERT_USER_NEWSLETTER_SUCCESS,
  INSERT_USER_NEWSLETTER_FAILURE,
  INSERT_FILTER_NEWSLETTER_START,
  INSERT_FILTER_NEWSLETTER_SUCCESS,
  INSERT_FILTER_NEWSLETTER_FAILURE,
  INSERT_USER_FILTER_NEWSLETTER_START,
  INSERT_USER_FILTER_NEWSLETTER_SUCCESS,
  INSERT_USER_FILTER_NEWSLETTER_FAILURE,
  CLEAR_ERROR,
} from "./newsletter.types";

export const insertUserNewsletterStart = () => ({
  type: INSERT_USER_NEWSLETTER_START,
});

export const insertUserNewsletterSuccess = () => ({
  type: INSERT_USER_NEWSLETTER_SUCCESS,
});

export const insertUserNewsletterFailure = (error) => ({
  type: INSERT_USER_NEWSLETTER_FAILURE,
  payload: error,
});

export const insertFilterNewsletterStart = () => ({
  type: INSERT_FILTER_NEWSLETTER_START,
});

export const insertFilterNewsletterSuccess = () => ({
  type: INSERT_FILTER_NEWSLETTER_SUCCESS,
});

export const insertFilterNewsletterFailure = () => ({
  type: INSERT_FILTER_NEWSLETTER_FAILURE,
});

export const insertUserFilterNewsletterStart = () => ({
  type: INSERT_USER_FILTER_NEWSLETTER_START,
});

export const insertUserFilterNewsletterSuccess = () => ({
  type: INSERT_USER_FILTER_NEWSLETTER_SUCCESS,
});

export const insertUserFilterNewsletterFailure = () => ({
  type: INSERT_USER_FILTER_NEWSLETTER_FAILURE,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});
