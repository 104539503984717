import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from "react-mixpanel";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";

import "./index.css";

mixpanel.init("d71fcf0f51503466e09f6e9d4d661a85");

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <MixpanelProvider mixpanel={mixpanel}>
          <App />
        </MixpanelProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
