import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { markDealAsFavorite } from "redux/saved-deals/saved-deals.actions";

import { toggleSingleDealStatus } from "redux/listing-details/listing-detail.actions";

import "./avatar.scss";

const Avatar = ({
  userCredential,
  onClick,
  savedNotification,
  favoriteNotification,
}) => {
  const [notificationShown, setNotificationShown] = useState(false);

  useEffect(() => {
    if (favoriteNotification) setNotificationShown(true);
    if (savedNotification) setNotificationShown(true);
    if (!savedNotification) {
      setNotificationShown(false);
    }
  }, [favoriteNotification, savedNotification]);

  return (
    <div
      data-testid="avatar-cred"
      className={`user-image user-image--circle ${
        notificationShown ? "notification-show" : ""
      }`}
      onClick={(e) => {
        if (notificationShown) setNotificationShown(false);
        onClick(e);
      }}
    >
      {userCredential}
    </div>
  );
};

const mapStateToProps = ({
  listingDetail: { favoriteNotification },
  savedDeals: { savedNotification },
}) => ({
  savedNotification,
  favoriteNotification,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSingleDealStatus: () => dispatch(toggleSingleDealStatus()),
  markDealAsFavorite: () => dispatch(markDealAsFavorite()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
