import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ReactComponent as Logo } from "../logo.svg";

import {
  toggleForgotPasswordPopup,
  toggleVerificationCodePopup,
  setEmail,
} from "redux/popup/popup.actions";
import { clearError, FetchEmail } from "redux/auth/auth.actions";
import Alert from "components/shared-components/alert/alert";

import "../popup.scss";

const ForgetPassword = ({
  isPopupVisible,
  toggleForgotPasswordPopup,
  toggleVerificationCodePopup,
  FetchEmail,
  errorMessage,
  clearError,
  setEmail,
}) => {
  const emailSend = (e) => {
    e.preventDefault();
    if (input) {
      setEmail(input);
      toggleForgotPasswordPopup();
      toggleVerificationCodePopup();
      FetchEmail(input);
      setInput("");
    }
  };
  const [input, setInput] = useState("");

  const changeInput = (e) => {
    setInput(e.target.value);
    if (errorMessage) clearError();
  };

  useEffect(() => {
    return () => {
      clearError();
    };
  }, [clearError]);

  return (
    <div className={`popup ${isPopupVisible ? "is-active" : ""}`}>
      <div className="popup-form login-form">
        <div className="logo">
          <Logo />
        </div>
        <div className="form-holder">
          <p>
            Enter your email address and we'll send you a link to set your
            password.
          </p>
          <form onSubmit={emailSend}>
            <div className="form-input">
              <input
                type="text"
                placeholder="Email"
                name="reset-email"
                id="reset-email"
                value={input}
                onChange={changeInput}
              />
            </div>
            <div className="form-input">
              <button className="button button--default">Send Code</button>
            </div>
          </form>
        </div>
      </div>
      {errorMessage && <Alert message={errorMessage} />}
    </div>
  );
};

const mapStateToProps = ({
  popups: { forgetPasswordPopupShown },
  auth: { loading, errorMessage },
}) => ({
  isPopupVisible: forgetPasswordPopupShown,
  loading,
  errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  toggleForgotPasswordPopup: () => dispatch(toggleForgotPasswordPopup()),
  toggleVerificationCodePopup: () => dispatch(toggleVerificationCodePopup()),
  FetchEmail: (param) => dispatch(FetchEmail(param)),
  clearError: () => dispatch(clearError()),
  setEmail: (param) => dispatch(setEmail(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
