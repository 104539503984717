import {
  FETCH_SAVED_DEALS_START,
  FETCH_SAVED_DEALS_SUCCESS,
  FETCH_SAVED_DEALS_FAILURE,
  FAVORITE_DEAL_SUCCESS,
  FAVORITE_DEAL_FAILURE,
  UNFAVORITE_DEAL_SUCCESS_FROM_LIST,
  UNFAVORITE_DEAL_SUCCESS,
  UNFAVORITE_DEAL_FAILURE,
} from "./saved-deals.types";

import { Auth } from "aws-amplify";
import { instance } from "api/rubik";
import axios from "axios";

export const fetchSavedDealsStart = () => ({
  type: FETCH_SAVED_DEALS_START,
});

export const fetchSavedDealsSuccess = (deals) => ({
  type: FETCH_SAVED_DEALS_SUCCESS,
  payload: deals,
});

export const fetchSavedDealsFailure = (error) => ({
  type: FETCH_SAVED_DEALS_FAILURE,
  payload: error,
});

export const favoriteDealSuccess = (deal) => ({
  type: FAVORITE_DEAL_SUCCESS,
  payload: deal,
});

export const favoriteDealFailure = (error) => ({
  type: FAVORITE_DEAL_FAILURE,
  payload: error,
});

export const unFavoriteDealSuccessFromList = (deal) => ({
  type: UNFAVORITE_DEAL_SUCCESS_FROM_LIST,
  payload: deal,
});

export const unFavoriteDealSuccess = (dealId) => ({
  type: UNFAVORITE_DEAL_SUCCESS,
  payload: dealId,
});

export const unfavoriteDealFailure = (error) => ({
  type: UNFAVORITE_DEAL_FAILURE,
  payload: error,
});

export const fetchSavedDealsAsync = ({ status }) => {
  return async (dispatch) => {
    dispatch(fetchSavedDealsStart());
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    const user_id = session.idToken.payload["cognito:username"];

    instance
      .get(`/crm-service/favorites?user_id=${user_id}`, {
        headers: {
          "X-Rubik-Authorization": `Bearer ${token}`,
        },
      })
      .then((deals) => {
        // console.log(deals.data);
        dispatch(fetchSavedDealsSuccess(deals.data));
      })
      .catch((error) => dispatch(fetchSavedDealsFailure(error)));
  };
};

export const markDealAsFavorite = ({ propertyId }) => {
  return async (dispatch) => {
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    const user_id = session.idToken.payload["cognito:username"];

    instance({
      method: "POST",
      url: `/crm-service/favorites`,
      headers: {
        "X-Rubik-Authorization": `Bearer ${token}`,
      },
      data: {
        user_id,
        listing_id: propertyId,
      },
    })
      .then((res) => {
        if (res) {
          dispatch(favoriteDealSuccess(propertyId));
        }
      })
      .catch((err) => {
        dispatch(favoriteDealFailure(err));
      });
  };
};

export const unmarkDealAsFavorite = ({ propertyId, removeFromList }) => {
  return async (dispatch) => {
    const session = await Auth.currentSession();
    const token = session.idToken.jwtToken;
    const user_id = session.idToken.payload["cognito:username"];

    instance
      .delete(
        `/crm-service/favorites?user_id=${user_id}&listing_id=${propertyId}`,
        {
          headers: {
            "X-Rubik-Authorization": `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res && removeFromList) {
          dispatch(unFavoriteDealSuccess(propertyId));
        }
        dispatch(unFavoriteDealSuccessFromList(propertyId));
      })
      .catch((err) => {
        dispatch(unfavoriteDealFailure(err));
      });
  };
};
