import React, { Suspense, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import Amplify from "aws-amplify";
import { connect } from "react-redux";
import { compose } from "redux";
import ReactGA from "react-ga";
import DriftWidget from "react-driftjs";

import ErrorBoundry from "components/shared-components/error-boundry/error-boundry";

import Header from "components/shared-components/header/header";
import Modal from "components/shared-components/modal/modal";
import Spinner from "components/shared-components/loading-indicator/loading-indicator";
import Footer from "components/shared-components/footer/footer";
import NextDeal from "components/landing-page/next-deal/next-deal";

import LoginPopUp from "components/authentication/login-popup/login-popup";
import SignUpPopUp from "components/authentication/signup-popup/signup-popup";
import ForgetPassword from "components/authentication/forgot-password/forgot-password";
import QuestionsPopup from "components/authentication/questions-popup/questions-popup";
import ResetPassword from "components/authentication/reset-password/reset-password";
import VerificationCode from "components/authentication/verification-code/verification-code";
import ScheduleCall from "components/authentication/schedule-call/schedule-call";
import SubmitOfferPopup from "components/listing-details-page/submit-offer-popup/submit-offer-popup";
import SignupVerificationCode from "components/authentication/signup-verification-code/signup-verification-code";
import ConfirmEmailPopup from "components/authentication/confirm-email-popup/confirm-email-popup";
import CheckEmailPopup from "components/authentication/check-email-popup/check-email-popup";

import { MixpanelConsumer } from "react-mixpanel";

import { checkUserSession, confirmEmail } from "redux/auth/auth.actions";
import {
  startFetchingQuestionsAsync,
  checkQuestionsStatus,
} from "redux/questions/questions.actions";
import {
  toggleSignInPopup,
  toggleSignUpPopup,
  toggleForgotPasswordPopup,
  toggleResetPasswordPopup,
  toggleVerificationCodePopup,
  toggleQuestionsPopup,
  closeAllPopups,
  toggleScheduleCallPopup,
  toggleSubmitForm,
  toggleSignupVerificationCodePopup,
  toggleConfirmEmailPopup,
  switchToSignIn,
  toggleCheckEmailPopup,
} from "redux/popup/popup.actions";

import "mapbox-gl/dist/mapbox-gl.css";

import aws_exports from "aws-exports";

import "stylesheets/main.scss";

const LandingPage = React.lazy(() =>
  import("pages/landing-page/landing-page")
);
const SearchPage = React.lazy(() => import("pages/search-page/search-page"));
const Faq = React.lazy(() => import("pages/faq/faq"));
const About = React.lazy(() => import("pages/about/about"));
const Dashboard = React.lazy(() => import("pages/dashboard-page/dashboard"));

Amplify.configure(aws_exports);

ReactGA.initialize("UA-106539669-2");
ReactGA.pageview(window.location.pathname + window.location.search);

function App({
  checkUserSession,
  checkQuestionsStatus,
  currentUser,
  location,
  loginPopupShown,
  registerPopupShown,
  questionsPopupshown,
  forgetPasswordPopupShown,
  verificationCodePopupShown,
  resetPasswordPopupShown,
  startFetchingQuestions,
  toggleSignInPopup,
  toggleSignUpPopup,
  toggleVerificationCodePopup,
  toggleForgotPasswordPopup,
  toggleResetPasswordPopup,
  toggleQuestionsPopup,
  submitFormPopupShown,
  closeAllPopups,
  questionsSession,
  toggleScheduleCallPopup,
  scheduleCallPopupShown,
  toggleSubmitForm,
  signupVerificationCodePopupShown,
  toggleSignupVerificationCodePopup,
  toggleConfirmEmailPopup,
  confirmEmailPopupShown,
  switchToSignIn,
  confirmEmail,
  emailVerified,
  checkEmailPopupShown,
  toggleCheckEmailPopup,
}) {
  const { pathname } = location;
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (location.pathname.startsWith("/account/verify")) {
      const [email, code] = location.search.split("&");
      const formatedEmail = email.split("=")[1];
      const formatedCode = code.split("=")[1];
      confirmEmail(formatedEmail, formatedCode);
    }
  }, [location]);

  useEffect(() => {
    checkUserSession();
  }, [checkUserSession]);

  // useEffect(() => {
  //   if (currentUser) checkQuestionsStatus();
  // }, [checkQuestionsStatus, currentUser]);

  // useEffect(() => {
  //   if (questionsSession === false) toggleQuestionsPopup();
  // }, [questionsSession, toggleQuestionsPopup]);

  useEffect(() => {
    startFetchingQuestions();
  }, [startFetchingQuestions]);

  return (
    <>
      {loginPopupShown && (
        <MixpanelConsumer>
          {(mixpanel) => (
            <Modal onClick={toggleSignInPopup}>
              <LoginPopUp mixpanel={mixpanel} />
            </Modal>
          )}
        </MixpanelConsumer>
      )}
      {registerPopupShown && (
        <MixpanelConsumer>
          {(mixpanel) => (
            <Modal onClick={toggleSignUpPopup}>
              <SignUpPopUp mixpanel={mixpanel} />
            </Modal>
          )}
        </MixpanelConsumer>
      )}
      {forgetPasswordPopupShown && (
        <Modal onClick={toggleForgotPasswordPopup}>
          <ForgetPassword />
        </Modal>
      )}
      {verificationCodePopupShown && (
        <Modal onClick={toggleVerificationCodePopup}>
          <VerificationCode />
        </Modal>
      )}
      {resetPasswordPopupShown && (
        <Modal onClick={toggleResetPasswordPopup}>
          <ResetPassword />
        </Modal>
      )}
      {scheduleCallPopupShown && (
        <Modal onClick={toggleScheduleCallPopup}>
          <ScheduleCall />
        </Modal>
      )}
      {submitFormPopupShown && (
        <Modal onClick={toggleSubmitForm}>
          <SubmitOfferPopup />
        </Modal>
      )}

      {signupVerificationCodePopupShown && (
        <Modal onClick={toggleSignupVerificationCodePopup}>
          <SignupVerificationCode />
        </Modal>
      )}

      {confirmEmailPopupShown && (
        <Modal
          onClick={() => {
            if (emailVerified) switchToSignIn();
            else {
              toggleConfirmEmailPopup();
              history.push("/");
            }
          }}
        >
          <ConfirmEmailPopup />
        </Modal>
      )}

      {checkEmailPopupShown && (
        <Modal onClick={toggleCheckEmailPopup}>
          <CheckEmailPopup />
        </Modal>
      )}

      <div className="rubik-app">
        <Header />

        <div style={{ minHeight: "100vh" }}>
          <Switch>
            <ErrorBoundry>
              <Suspense fallback={<Spinner top={"100%"} />}>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <MixpanelConsumer>
                      {(mixpanel) => (
                        <LandingPage {...props} mixpanel={mixpanel} />
                      )}
                    </MixpanelConsumer>
                  )}
                ></Route>
                <Route
                  path="/search"
                  render={(props) =>
                    !currentUser ? (
                      <Redirect to="/" />
                    ) : (
                      <MixpanelConsumer>
                        {(mixpanel) => (
                          <SearchPage {...props} mixpanel={mixpanel} />
                        )}
                      </MixpanelConsumer>
                    )
                  }
                />
                <Route
                  path="/dashboard"
                  render={(props) =>
                    !currentUser ? (
                      <Redirect to="/" />
                    ) : (
                      <MixpanelConsumer>
                        {(mixpanel) => (
                          <Dashboard {...props} mixpanel={mixpanel} />
                        )}
                      </MixpanelConsumer>
                    )
                  }
                />
                <Route
                  path="/faq"
                  render={() => (
                    <MixpanelConsumer>
                      {(mixpanel) => <Faq mixpanel={mixpanel} />}
                    </MixpanelConsumer>
                  )}
                />
                <Route
                  path="/about"
                  render={() => (
                    <MixpanelConsumer>
                      {(mixpanel) => <About mixpanel={mixpanel} />}
                    </MixpanelConsumer>
                  )}
                />
              </Suspense>
            </ErrorBoundry>
          </Switch>
        </div>

        {!pathname.startsWith("/search") && !pathname.startsWith("/dashboard") && (
          <MixpanelConsumer>
            {(mixpanel) => (
              <>
                <NextDeal />
                <Footer mixpanel={mixpanel} />
              </>
            )}
          </MixpanelConsumer>
        )}
      </div>
      <DriftWidget appId="xvckkgtw54kz" />
    </>
  );
}

const mapStateToProps = ({
  auth: { currentUser, emailVerified },
  questions: { questionsSession },
  popups: {
    loginPopupShown,
    registerPopupShown,
    forgetPasswordPopupShown,
    verificationCodePopupShown,
    resetPasswordPopupShown,
    questionsPopupshown,
    scheduleCallPopupShown,
    submitFormPopupShown,
    signupVerificationCodePopupShown,
    confirmEmailPopupShown,
    checkEmailPopupShown,
  },
}) => ({
  currentUser,
  emailVerified,
  loginPopupShown,
  registerPopupShown,
  forgetPasswordPopupShown,
  verificationCodePopupShown,
  resetPasswordPopupShown,
  questionsPopupshown,
  questionsSession,
  scheduleCallPopupShown,
  submitFormPopupShown,
  signupVerificationCodePopupShown,
  confirmEmailPopupShown,
  checkEmailPopupShown,
});

const mapDispatchToProps = (dispatch) => ({
  checkUserSession: () => dispatch(checkUserSession()),
  startFetchingQuestions: () => dispatch(startFetchingQuestionsAsync()),
  toggleSignInPopup: () => dispatch(toggleSignInPopup()),
  toggleSignUpPopup: () => dispatch(toggleSignUpPopup()),
  toggleForgotPasswordPopup: () => dispatch(toggleForgotPasswordPopup()),
  toggleVerificationCodePopup: () => dispatch(toggleVerificationCodePopup()),
  toggleResetPasswordPopup: () => dispatch(toggleResetPasswordPopup()),
  toggleQuestionsPopup: () => dispatch(toggleQuestionsPopup()),
  closeAllPopups: () => dispatch(closeAllPopups()),
  checkQuestionsStatus: () => dispatch(checkQuestionsStatus()),
  toggleScheduleCallPopup: () => dispatch(toggleScheduleCallPopup()),
  toggleSubmitForm: () => dispatch(toggleSubmitForm()),
  toggleSignupVerificationCodePopup: () =>
    dispatch(toggleSignupVerificationCodePopup()),
  toggleConfirmEmailPopup: () => dispatch(toggleConfirmEmailPopup()),
  switchToSignIn: () => dispatch(switchToSignIn()),
  confirmEmail: (username, code) => dispatch(confirmEmail(username, code)),
  toggleCheckEmailPopup: () => dispatch(toggleCheckEmailPopup()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(App);
